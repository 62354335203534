import { gql } from 'apollo-boost';

export const CHECK_EMAIL = gql`
  mutation checkEmail ($email: String!, $isSalesPartner: Boolean) {
    checkEmail(email: $email, isSalesPartner: $isSalesPartner) {
      valid
    }
  }
`;

export const CHECK_SUBSIDIARY_COMPANY = gql`
  mutation checkSubsidiaryCompany ($email: String!, $name: String!) {
    checkSubsidiaryCompany(email: $email, name: $name) {
      valid
      errors {
        field
        messages
      }
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($companyId: ID){
    myCompany (companyId: $companyId){
      id
      name
      typeOfCompany
      typeOfCompanyDisplay
      editorsNumber
      salesPartnerNumber
      salesIndustry
      subsidiaryCompaniesNumber
      createdAt
      streetAndNumber
      zipCode
      industry
      city
      country {
        id
        country
      }
      isPartOfGroup
      nameCompanyGroup
      numberOfEmployee
      revenueLastYear
      highExportRate
      exportOrderUsually
      rateOfExperience
      coverageNumber
      administrator {
        firstName
        lastName
        email
        salutation
        firstName
        lastName
        phoneNumber
        jobTitle
      }
      parent {
        id
        name
      }
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query getTeamMembers($companyId: ID!, $clientType: Int, $name: String, $includeTfsClient: Boolean = false){
    getTeamMembers (companyId: $companyId, clientType: $clientType, name: $name, includeTfsClient: $includeTfsClient){
      id
      email
      firstName
      lastName
      createdAt
      jobTitle
      company {
        id
        name
      }
    }
  }
`;

export const GET_TEAM_MEMBERS_SUBSIDIARY = gql`
  query getSubsidiaryCompanies($companyId: ID!, $name: String){
    getSubsidiaryCompanies (companyId: $companyId, name: $name){
      id
      name
      createdAt
      administrator {
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_TEAM_NOTIFICATION = gql`
  query getTeamManagementNotification($companyId: ID!) {
    getTeamManagementNotification(companyId: $companyId) {
      notificationNumber
    }
  }
`;
