import { gql } from 'apollo-boost';

export const ADD_MEMBERS = gql`
  mutation ($emails: [String]!, $companyId: ID!, $clientType: Int) {
    addMembers(emails: $emails, companyId: $companyId, clientType: $clientType) {
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const ADD_SUBSIDIARY_COMPANIES = gql`
mutation ($subsidiaryCompanies: [SubsidiaryCompanyInput]!, $companyId: ID!) {
  addSubsidiaryCompany(subsidiaryCompanies: $subsidiaryCompanies, companyId: $companyId) {
    success
    errors {
      field
      messages
    }
  }
}
`;

export const REMOVE_MEMBERS = gql`
  mutation removeTeamMember($companyId: ID!, $userId: ID!) {
    removeTeamMember(companyId: $companyId, userId: $userId){
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const REMOVE_MEMBERS_SUBSIDIARY = gql`
  mutation removeSubsidiaryCompany($groupCompanyId: ID!, $subsidiaryCompanyId: ID!) {
    removeSubsidiaryCompany(groupCompanyId: $groupCompanyId, subsidiaryCompanyId: $subsidiaryCompanyId){
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_REGISTER_STEP = gql`
  mutation {
    updateRegisterStep {
      success
    }
  }
`;

export const RESTART_NOTIFICATION_NUMBER = gql`
  mutation restartTeamManagementNotification($companyId: ID!) {
    restartTeamManagementNotification(companyId: $companyId) {
      success
      errors {
        field
        messages
      }
    }
  }
`;

export const UPDATE_SALES_COMPANY = gql`
  mutation ($input: UpdateSalesCompanyInput!) {
    updateSalesCompany(input: $input) {
      id
      name
      errors {
        field
        messages
      }
    }
  }
`;
