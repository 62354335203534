import React, { Component } from 'react';
import { ApolloConsumer } from '@apollo/react-hooks';

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component,
      });
    }

    render() {
      const { component: DownloadComponent } = this.state;

      return DownloadComponent ? (
        <ApolloConsumer>
          {(client) => <DownloadComponent {...this.props} client={client} />}
        </ApolloConsumer>
      ): null;
    }
  }

  return AsyncComponent;
}
