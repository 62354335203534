import React from 'react';

function ClickToAccess(props) {
  const {
    onClick, component: Component, hasAccess, ...inComingProps
  } = props;

  if (hasAccess) {
    return <Component onClick={onClick} {...inComingProps} />;
  }

  return (
    null
  );
}

export default ClickToAccess;
