import React from 'react';
import {
  Row,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import './styles.scss';

const withLoading = (WrappedComponent) => class extends React.Component {
  constructor(props) {
    super(props);
    this.showLoading = this.showLoading.bind(this);
    this.state = {
      showLoader: false,
    };
  }

  showLoading(value) {
    this.setState({
      showLoader: value,
    });
  }

  render() {
    const { showLoader } = this.state;
    const antIcon = <LoadingOutlined spin />;

    return (
      [
        showLoader && (
          <Row className="loading-page">
            <Spin indicator={antIcon} />
          </Row>
        ),
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WrappedComponent
          key="withLoadingComponent"
          loading={showLoader}
          showLoading={this.showLoading} {...this.props}
        />,
      ]
    );
  }
};

export default withLoading;
