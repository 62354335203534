import { FILE_MAX_SIZE } from '../globals/settings';

const errorMessages = (t) => ({
  mandatory: t('pleaseFillField'),
  wrongEmailPassword: t('wrongEmailPassword'),
  onlyNumber: t('onlyNumber'),
  onlyLetterAndCaracter: t('onlyLetterAndCharacter'),
  lessThanMb: `File must be lower than ${FILE_MAX_SIZE} MB`,
  fileType: t('fileType'),
});

export default errorMessages;
