import React, { Component } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

import eye from '../../../media/icons/passwordEye.svg';
import openEye from '../../../media/icons/textEye.svg';

import './styles.scss';

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

  getIcon() {
    const { visible } = this.state;

    return (
      <img
        onClick={() => {
          const { visible } = this.state;

          this.setState({
            visible: !visible,
          });
        }}
        className="password-image"
        src={visible ? openEye : eye}
        alt="password-eye"
      />
    );
  }

  render() {
    const {
      placeholder, value, onChange, dataCy, name,
    } = this.props;
    const { visible } = this.state;

    return (
      <Input
        type={visible ? 'text' : 'password'}
        size="large"
        suffix={this.getIcon()}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        data-cy={dataCy}
        name={name}
      />
    );
  }
}

PasswordInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
};

export default PasswordInput;
