import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Dropdown,
  Menu,
  Badge,
  Avatar,
} from 'antd';
import {
  DownOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import { useLazyQuery } from '@apollo/react-hooks';
import userMenuItems from './constants';
import { ADMIN_LOGOUT } from '../../graphql/queries/Admin';

import './styles.scss';

const UserMenu = (props) => {
  const {
    t, setLoginState, user, history, setSiderState,
  } = props;
  const [adminLogout, { data }] = useLazyQuery(ADMIN_LOGOUT);
  useEffect(() => {
    if (data) {
      localStorage.clear();
      setSiderState(false);
      setLoginState(false);
    }
  }, [data, setLoginState, setSiderState]);

  const menuAction = {
    logout: () => {
      adminLogout();
    },
    profile: () => history.push('/profile'),
  };

  const getUserDropdown = () => (
    <Menu
      selectable
      className="custom-dropdown"
      data-test-id="dashboard-profile-options-menu"
      onClick={({ key }) => {
        menuAction[key]();
      }}
    >
      {
        userMenuItems(t).map((menu) => (
          <Menu.Item key={menu.key}>
            <menu.icon className="mr-2" />
            { menu.title }
          </Menu.Item>
        ))
      }
    </Menu>
  );

  return (
    <Dropdown
      /* eslint-disable-next-line no-undef */
      getPopupContainer={() => document.getElementById('userDropdown')}
      placement="bottomCenter"
      className="username-header"
      overlay={getUserDropdown()}
    >
      <div>
        <Badge className="show-avatar">
          <Avatar size={38} icon={<UserOutlined />} />
        </Badge>
        <span className="bold hidden-name">
          {user ? `${user.firstName} ${user.lastName}` : t('user')}
          <DownOutlined className="ml-2" />
        </span>
      </div>
    </Dropdown>
  );
};

UserMenu.propTypes = {
  t: PropTypes.func.isRequired,
  setLoginState: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.object, PropTypes.bool])).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  setSiderState: PropTypes.func,
};

UserMenu.defaultProps = {
  setSiderState: () => {},
};

export default withRouter(withTranslation()(UserMenu));
