import errorMessages from '../../constants/messages/errors';

const formItems = (t) => [
  [
    {
      name: 'base_password',
      type: 'password',
      label: t('password'),
      rules: [{
        required: true,
        whitespace: true,
        message: errorMessages(t).mandatory,
      },
      ],
      placeholder: t('passwordPH'),
    },
  ],
];

export default formItems;
