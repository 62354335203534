import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import SiderContent from '../SiderContent';
import { SiderContext } from '../../../contexts';

import './styles.scss';

const SiderDrawer = ({ siderMenus }) => {
  const siderContext = useContext(SiderContext);

  return (
    <Drawer
      className="custom-drawer"
      width={280}
      closable={false}
      placement="left"
      visible={siderContext.drawerVisible}
      onClose={() => {
        siderContext.setDrawerVisible(false);
      }}
      destroyOnClose
    >
      <SiderContent siderMenus={siderMenus} />
    </Drawer>
  );
};

SiderDrawer.propTypes = {
  siderMenus: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SiderDrawer;
