/*
  English Resource
 */

const resourceEn = {
  // Views --------

  // Login
  loginButton: 'Log-in',
  registerButton: 'Log-in',
  forgotPassword: 'Forgotten your password?',
  emailAddress: 'Email address',
  emailPH: 'Your email address',
  password: 'Password',
  passwordPH: 'Your password',

  // RegisterLoginCard
  haveAccount: 'You already have a TraFinScout account?',
  haveNotAccount: 'You don\'t have a TraFinScout account?',
  registerNow: 'Log-in',
  createAccount: 'Set up an account',

  // Home
  editCustomerProfile: 'Complete customer profile',
  customerProfileMessage: 'To send requests, please complete your customer profile.',
  customProfileMessage: 'To send requests, please complete your customer profile.',
  welcomeUserArea: 'Welcome to your personal user domain!',
  createNewRequest: 'Prepare a new request.',
  productOverview: 'Other TraFinScout products.',
  searchLetterInfo: 'Find a letter of credit confirmation.',
  searchLetterDescription: 'Letters of credit and payment confirmations are good, frequently used instruments to secure payment risks.',
  createRequest: 'Prepare a request',
  lookForBuyer: 'Find buyer\'s credit from EUR 10m',
  lookForBuyerDescription: 'Buyer\'s loans are an attractive means for your customers in emerging and developing countries to finance capital goods in the medium and long-term.',
  searchForfaiting: 'Find forfaiting offer from EUR 10m',
  searchForfaitingDescription: 'Sometimes supplier\'s credit is the faster and simpler way to finance your customer. To retain your liquidity simply sell your receivable (forfaiting).',
  findCreditInsurance: 'Find Credit Insurance',
  findCreditInsuranceDescription: 'Deliveries to and financing of customers abroad is always associated with risks. Make sure you receive the agreed payments. Sovereign guarantees and private risk insurance help you achieve this.',
  exportTicket: 'Small Ticket Export Finance',
  exportTicketDescription: 'Finance your export orders between EUR 250,000 and EUR 10,000,000 quickly and easily through us.',
  addedAt: 'Added on: {{ date }}',
  admin: 'Administrator: {{ firstName }} {{ lastName }}',
  editor: 'Editor',
  salesPartnerCounter: 'Maintaining sales partner',
  changeCompany: 'Change Company',
  affiliates: 'Affiliated Companies',
  goParent: 'Go Parent',
  successInvitation: 'Your invitation was successful',
  exporterCounter: 'Betreute Exporteure',
  invite: 'Invite',

  // RequestTable
  ongoingInquiries: 'My current requests',
  inquiryTitle: 'Subject of request',
  art: 'Type',
  status: 'Status',
  finished: 'Completed',
  lastChange: 'Last change',
  processRequest: 'Amend request',

  // NEW REQUEST
  createRequestNow: 'Open a request now',
  nextSteps: 'Next steps',
  overview: 'Overview',
  productSelection: 'Product selection',
  requestTitle: 'Request heading',
  requestTitlePH: 'e.g. customer name or transaction number',
  productListError: 'You do not have products assigned to your company. Please contact with TranFinScout.',

  // PasswordReset
  emailAddressLabel: 'Email address',
  resetPassword: 'Reset password',
  successReset: 'Please check your in-box and click the link in the TraFinScout email to reset the password.',
  resetPasswordTitle: 'Reset your password',
  sendEmailText: 'We sent you an email.',
  backToLogin: 'Return to log-in',

  // Password
  passwordRule1: 'at least eight characters long.',
  passwordRule2: 'contain at least one lower-case letter and one upper-case letter.',
  passwordRule3: 'contain at least one number.',
  passwordRule4: 'at least one special character.',
  enterNewPassword: 'Enter your new password',
  saveNewPassword: 'Save new password',
  backToHome: 'Return to home',
  validatePassword: 'The password should:',
  passwordResetFailed: 'Password reset failed',
  yourNewPassword: 'Your new password',

  // ProfileUpdate
  successProfileUpdate: 'Update successful',
  personalInfo: 'Personal information',
  changePassword: 'Change password',
  exporterInfo: 'Exporter information',
  saveProfile: 'Save profile',
  editProfile: 'Edit profile',
  yes: 'Yes',
  no: 'No',
  updateExporterProfile: 'Creating customers',
  updateExporterProfileMessage: 'To send inquiries, please create a customer profile.',

  // personalForm
  salute: 'Title',
  firstName: 'First name',
  firstNamePH: 'Your first name',
  lastName: 'Last name/family name',
  lastNamePH: 'Your last name',
  company: 'Business',
  companyPH: 'eg. Company name GmbH/Ltd.',
  phoneNumber: 'Telephone no. (optional)',
  phoneNumberPH: 'e.g. +49187625375',
  phoneNumberFormat: 'Please enter your telephone no. In the format +49187625375 or 0187625375.',
  email: 'Business email',
  saluteExporter: 'Name of the contact person at the customer',
  exporterFunction: 'Function in the company',
  exporterFunctionPH: 'Function',

  // exporterForm
  nameCompany: 'Company name',
  nameCompanyPH: 'Your company name',
  street: 'Street No.',
  streetPH: 'e.g. Goethestr. 23. 3rd floore',
  place: 'City',
  placePH: 'Frankfurt a.M.',
  plz: 'Postcode',
  plzPH: '60353',
  land: 'Country',
  landPH: 'Germany',
  industry: 'Industrial sector',
  industryPH: 'Select an industrial sector',
  hasCompanyGroup: 'Is your company part of a consolidated enterprise group of companies?',
  groupName: 'Name of your group or parent company',
  groupNamePH: 'e.g. Fincite Group',
  coverageNumber: 'Please enter your Euler Hermes number (Deckungsnehmer Nr.) if known.',
  coverageNumberPH: 'eg. Deckungsnehmer Nr.',
  employees: 'Number of employees',
  employeesPH: '0 - 50 employees',
  revenue: 'Turnover for the last full business year',
  revenuePH: '< 10m',
  exportQuote: 'How high is your export quota',
  exportQuotePH: '< 25%',
  exportOrder: 'Size of your average export order',
  exportOrderPH: '250.000 - 500.000',
  rateExperience: 'How do you rate your experience regarding export finance and export credit instruments?',

  // Register
  successRegister: 'Thank you for your registration!',
  checkEmailToActive: 'Please check your email and activate your account.',
  passwordValidation: 'The password should',
  woman: 'Mrs.',
  mr: 'Mr.',
  clientType: 'They register as',
  clientTypePH: 'Choose your profile',
  termsCondition: 'Please accept the declaration of consent and the data protection declaration to continue.',
  agreeTC1: 'I agree to the processing and use of my data according to the',
  agreeTC2: '',
  termsOfUse: 'Terms of Use',
  dataProtection: 'Privacy Policy',
  agreeTC3: '',
  and: 'and',
  companyNotExists: 'This company is already registered. For more information, please contact us: +49 69 153259345',
  jobTitle: 'Job Title (optional)',
  jobTitlePH: 'Job Title',

  // Sales partner types
  freelancerCompany: 'Self-employed',
  partnershipCompany: 'Partnership',
  corporationCompany: 'Corporation',
  salesUpdateProfile: 'Unternehmensprofil Partner ergänzen',
  addExporterToInquiry: 'Um Anfragen zu erstellen, fügen Sie bitte Exporteure hinzu.',
  addExporter: 'Exporteur hinzufügen',

  // RequestForm

  // Control
  dealBtn: 'Send document list to exporter',

  // Header
  sendRequestQuestion: 'Would you like to submit your request?',
  sendRequestExporter: 'Möchten Sie Ihre Anfrage zu Ihre Exporteur senden?',
  successSendExporterRequest: 'Ihr Anfrage wurde erfolgreich zu Ihre Exporteur gesendet.',
  successSendRequest: 'Your request was successfully transmitted.',
  followRequestStatus: 'You can follow the status of your request at any time through "Deal Tracking".',
  abort: 'Cancel',
  submit: 'Send',
  submitExporter: 'Zum Exporteur senden',
  goToDeal: 'To Deal Tracking',
  lastSave: 'Last saved',
  of: 'From',
  the: 'The',
  continueLater: 'Continue later',
  saveContinueLater: 'Save & continue later',

  // Overview
  downloadPDF: 'Download pdf',
  finishedRequest: 'Done',
  inProgressRequest: 'Processing',
  toEdit: 'Process ',
  preview: 'Preview',

  // ReceivedDocuments
  documentsAvailableForExporters: 'Here you can find all documents we have provided for the exporter',
  documentsAvailableForYou: 'Here you can find all documents we have provided for you',
  documentsExporterProvidesForYou: 'Here you can find all documents provided for us by the exporter',
  documentsYouProvides: 'Here you can find all documents you have provided for us',

  // ReceivedDocuments
  fileName: 'File name',
  step: 'Step',
  date: 'Date',

  // StepForm
  incompleteFormMessage: 'Please answer marked questions to complete and submit your request',
  toNextStep: 'Next step ',
  saveGoNextStep: 'Save & continue to next step',

  // StepsSidebar
  documentsReceived: 'Documents received',
  uploadDocuments: 'Uploaded documents',
  dealStatusControl: 'Deal Status Control',

  // UploadDocument
  toUploadDocument: 'Upload documents',
  toUploadDocumentBtn: 'Upload new documents',
  dealNumber: 'Deal number',
  projectName: 'Project name',
  lastUpdateOn: 'Last updated on',
  requiredDocuments: 'Necessary documents',
  uploadCoverApplication: 'Please upload your request for cover here',
  otherDocuments: 'Other documents',

  // UploadModalButton
  upload: 'Upload',
  dealNumberPH: 'Select deal',
  saveBtn: 'Save',
  addExtraDocumentText: 'Add document',
  documentName: 'Document Name',
  documentNamePH: 'Document name letter',
  uploadExtraFilesSuccess: 'Your documents have been uploaded successfully.',

  // Authentication
  wrongPassword: 'Wrong password',
  basicAuthentication: 'Base authentication',
  authorize: 'Authorize',

  // Contact
  subject: 'Please provide your topic',
  subjectPH: 'Please enter here',
  contactYou: 'When may we contact you?',
  contactAnytime: 'I\'m flexible, contact me anytime',
  howContactYou: 'How would you like us to contact you?',
  contactByEmail: 'Through the email provided in my account',
  willContactYouByEmail: 'We will contact you through the email you used to register on TraFinScout.',
  viaAnotherEmail: 'Through a different email address',
  yourEmailAddress: 'Your email address',
  callMeAtNumber: 'Please call me on the following number',
  callMeAtNumberPH: 'e.g. +49187625375',
  thanksForContactUs: 'Thank you for contacting us. We\'ll be in touch shortly.',
  makeAnAppointment: 'Schedule an appointment with us',
  appointmentDescription: 'You would like to talk to us? Certainly. Let us know below when would be the best time for us to contact you.\n You may also contact us at any time via email or on the telephone no. you see on the right.',
  agreementText: 'By clicking on "send" i agree to allow TraFinScout GmbH to contact me by email or telephone (if you have provided a number) to process my request. This is in concurrence with our data protection declaration.',
  sendAppointmentRequest: 'Send appointment request',
  howToFindUs: 'How to find us',
  address: 'Address',
  addressExample: 'Solmsstrasse 4, 60486 Frankfurt',
  scheduleExample: '(Montag – Freitag 9:00 – 18:00)',
  contact: 'contact',
  contactExample: '+49 (0)69 153 249 345',
  emailExample: 'kundenservice@trafinscout.com',

  // Team Management
  teamsManagement: 'Team management',
  editCompanyProfile: 'Edit company profile',
  added: 'Added',
  companyType: 'Company type',
  administrator: 'Administrator',
  editorTab: 'Editor',
  partner: 'Affiliated companies',
  supervisor: 'Supervising Distributors',
  companies: 'Company',
  function: 'Function',
  registeredOn: 'Registered on',
  addSalesPartner: 'Add sales partner',
  addSingleMember: 'Invite editor',
  addGroupMember: 'Add subsidiaries',
  addSalesPartnerFooter: 'If you want to change the rights of a sales partner, please contact us',
  addSingleMemberFooter: 'If the rights of a processor should be changed, please contact us',
  addGroupMemberFooter: 'If you want to change the rights of an editor, please contact us',
  searchPH: 'All distributors',
  remove: 'Delete',
  editorRightsModal: 'Rights of the',
  individual: 'Editor',
  group: 'Subsidiaries',
  salesGroup: 'Distributors',
  sendInvitation: 'Send invitation',
  removeUser: 'Do you want to DELETE {{ userName }}?',
  removeUserConfirm: '{{ userName }} has been successfully deleted.',
  exporterTeam: 'Exporteure',

  // DealTracking
  product: 'Product',
  statusUpdateOn: 'Status updated on ',
  importer: 'Importer',
  viewRequest: 'Submit request',
  dealTracking: 'Deal Tracking',

  // Deal Filter
  filter: 'Filter',
  dealNumberLabel: 'Deal Number',
  dealNumberPlaceholder: 'Deal Number',
  productLabel: 'Produkt',
  productPlaceholder: 'Produkt',
  statusLabel: 'Status',
  statusPlaceholder: 'Status',
  importerLabel: 'Importeur',
  importerPlaceholder: 'Importeur',
  clear: 'Clear',
  clearAllFilters: 'Clear all filters',

  // InvitationRegister
  exporter: 'Exporter',
  salesPartner: 'Sales partner',
  teamMember: 'Editor',

  // Components -------

  // UserProfile
  successProfileSaved: 'Thank you! Your customer profile was saved.',
  clientProfile: 'Customer profile',
  stepCounter: 'Steps <1>{{ step }}</1>/{{ maxStep }}',
  next: 'Continue',
  additionalEntryDescription: 'You can also add additional editors and affiliates at any time in team management.',
  inviteEditor: 'Invite additional editors',
  editorRights: 'Rights of the editors',
  addEmail: 'ADD EMAIL ADDRESS',
  dashboardAccessDescription: 'Editors can access the dashboard',
  addAccessDescription: 'Editors can create a new request',
  sendAccessDescription: 'Editors can send a request to TraFinScout',
  documentAccessDescription: 'Editors can upload documents',
  addCompanyTitle: 'Add connected companies',
  companyNameLabel: 'COMPANY NAME',
  singleCompany: 'Single company',
  groupCompany: 'Parent company (group/corporation)',
  parentCompany: 'Subsidiary (group/corporation)',
  companyTypeLabel: 'Type of company',
  parentCompanyLabel: 'Name of your group or parent company',
  emailError: 'Invalid format "{{ errorValue }}"',
  commonEmailError: 'Invalid format',
  successEmails: 'The members were added to this company successfully.',
  add: 'ADD',
  emailBackendError: 'This email {{ errorValue }} is not allowed.',

  // Rights Editor
  dashboardAccess: 'Access to the dashboard',
  addAccess: 'Create new requests',
  sendAccess: 'Send requests',
  documentAccess: 'Upload documents',
  singleCompanyType: 'Allow editors',
  dashboardAccessDescriptionSingle: 'Editors can access the dashboard and view all information',
  addAccessDescriptionSingle: 'Editors can create a new request',
  sendAccessDescriptionSingle: 'Editors can send a request to TraFinScout',
  documentAccessDescriptionSingle: 'Editors can upload documents',
  dashboardAccessDescriptionGroup: 'Distributor can access dashboard and view all information',
  addAccessDescriptionGroup: 'The reseller can create a new request',
  sendAccessDescriptionGroup: 'The distributor can send a request to TraFinScout',
  documentAccessDescriptionGroup: 'The distributor can upload documents',
  dashboardAccessDescriptionParent: 'Editors can access the dashboard and view all information',
  addAccessDescriptionParent: 'Editors can create a new request',
  sendAccessDescriptionParent: 'Editors can send a request to TraFinScout',
  documentAccessDescriptionParent: 'Editors can upload documents',

  // UserMenu
  logout: 'Log out',
  user: 'User',

  // StepPanel -> finalStep
  hasBeenConfirmed: 'Confirmed by you',

  // Sider
  // constants
  contactHelp: 'Contact & help',

  // SiderDrawer
  weAreHereForYou: 'WE\'RE HERE FOR YOU',
  weAreHereForYouExample: '+49 (0) 69 153249345',

  // Components --------

  // CompanySelector
  companySelectorName: 'My company:',

  // DashboardCardSimple
  viewTracking: 'View tracking',

  // DocumentDownloadModal
  pdfTitle: 'Download',

  // DocumentUploadForm
  sendedDocumentFeedback: 'The document was sent.',
  declineDocumentFeedback: 'The offer was refused.',
  requestDocumentFeedback: 'The document list was shared with the exporter',
  fileFeedback: 'Sent documents:',
  requestFileFeedback: 'Document list',
  fileNameRequired: 'Description of the input file',
  uploadDocumentText: 'Upload documents',
  addDocumentText: 'Add',
  documentRequestButton: 'Send document list',
  documentPlaceholder: 'Select documents',
  documentDefaultOption: '--',
  fileRequired: 'this file is required',
  dragFile: 'drag this file here or upload it',
  uploadFileButton: 'Upload file',
  uploadedDocuments: 'Uploaded documents',
  uploadFileTitle: 'Upload documents',
  dateText: 'Date : ',
  uploadedFiles: 'Uploaded files',
  fileUploaded: 'File uploaded',

  // Footer
  privacy: 'Data protection',
  imprint: 'Legal notice',

  // Header
  backToTracking: 'Return to tracking overview',
  requestHelp: 'Request help',
  headerPhone: '+49 (0) 69 153249345',

  // Horizontal Time Line
  timeLineTooltip: 'Please upload the missing documents',
  timeLineDeal: 'Deal tracking',
  goUploadDocument: 'Go to uploading documents',

  // Item Table
  downloadDocument: 'Download documents',

  // Mini Progress Bar
  uploadText: 'Uploaded',
  uploadTextPercentage: '% hochgeladen',

  // FAQ
  faqTitle: 'FAQs',
  faqSubtitle: 'Haben Sie Fragen? Lassen Sie uns Ihnen helfen, die Antwort hier zu finden!',
  keywordLabel: 'Suche nach Schlüsselwörtern',
  keywordPlaceholder: 'Suche nach Schlüsselwörtern…',
  selectKeyword: 'oder Schlüsselwort auswählen',

  // Hermes Cover Pre-Check
  hermesCover: 'Hermes Cover Pre-Check',

  // Calculator
  calculator: 'Fee calculator',
  calculatorsTitle: 'What does Hermes cover cost (for terms > 2 years with half-yearly repayment)?',
  calculatorTooltip: 'Please use this calculator to calculate the Hermes fee for receivables cover with a financing term of <0> at least </0> two years.',
  calculatorInterestTooltip: 'Please use this calculator to calculate the Hermes fee for receivables coverage with a financing term of <0> at least </0> two years and six-monthly repayment.',
  contractValue: 'Contract value',
  deposit: 'Deposit',
  depositFooter: 'at least 15% required',
  notPermitted: 'Not permitted',
  validInputPercentage: 'Please enter at least 15%.',
  creditAmount: 'Loan Amount',
  categoryLand: 'Country category',
  categoryLandTooltip: 'The country category is a country\'s country risk class as defined by the OECD. The country categories are automatically determined for the selected country on the basis of the currently applicable risk categories. ',
  gradeTitle: 'Your credit rating of the buyer',
  gradeLabel: 'Buyer Category',
  gradeTooltip: 'The buyer category is the rating grade given by Euler Hermes to the buyer. The buyer category also depends on the business environment in the respective country. An average creditworthiness in Germany can be good or even very good in another country.'
    + 'As a rough guide, you can assume the following in relation to the respective country: <0 /> <0 />'
    + 'CC0 = excellent credit rating (this result is very unlikely) <0 />'
    + 'CC1 = very good credit rating <0 />'
    + 'CC2 = good credit rating <0 />'
    + 'CC3 = average credit rating <0 />'
    + 'CC4 = sufficient monity <0 />'
    + 'CC5 = poor credit rating <0 /> <0 />'
    + 'Attention: not every buyer category is permitted for every country category. From country category 5, not all buyer categories are eligible for cover. If a combination of country category and buyer category is not allowed, you will get the result not allowed. ',
  riskPeriod: 'Risk period for coverage costs',
  startDelivery: 'Start of delivery until complete delivery',
  monaten: 'months',
  years: 'years',
  financingTerm: 'Financing term',
  monthsTooltip: 'To calculate the risk period, the so-called lead time is added to the financing period. The lead time is the period in which Euler Hermes has already granted cover, but has not yet been fully delivered or paid. <0 /> <0 /> '
    + 'You can enter this period in months here. Since the effect on the premium is relatively small, the pure financing term is a good reference value for the premium. ',
  calculate: 'Calculate',
  tableColumn: 'Calculated costs of Hermes cover',
  tableColumn2: 'Amounts in EUR',
  creditCoverage: 'Supplier final credit coverage with 15% deductible (standard)',
  financialCreditCoverage: 'Financial credit coverage (e.g. for buyer credit, payment is also financed)',
  suppliedCreditCoverage: 'Supplier credit coverage with deductible reduced to 5%',
  onlyBuyerCredit: 'Loan amount when co-financing the cover costs (e.g. buyer credit)',
  tableColumnTooltip: 'A fee must be paid for taking over Hermes cover, the amount of which essentially depends on the risk period, the country category and the buyer category. <0 /> <0 /> '
    + 'The amount of the deductible also has an effect on the supplier end credit cover. Stantard is a 15% deductible. That is often unfavorable, '
    + 'especially if you are looking for forfaiting. It would be better to apply for the deductible to be reduced to 5%. This increases the fee by 10%, '
    + 'However, you can usually relieve your balance sheet with forfaiting. <0 /> <0 /> '
    + 'In the case of a buyer credit, it is common to already take into account the payment amount for the financial credit cover.',

  excelent: 'Excellent',
  good: 'Very good',
  nice: 'Good',
  average: 'Average',
  enough: 'Sufficient',
  weak: 'Weak',
  hermesCalculator: 'Premium calculator Hermes coverage',
  interestCalculator: 'Cost-covering buyer interest calculator',
  interestTitle: 'Cost-covering buyer interest rate when forfaiting a supplier credit (from 2 years financing term)',
  leadTime: 'Lead Time',
  riskPeriodTitle: 'Risk period',
  riskPeriodTitleTooltip: 'The risk period can be longer than the pure financing period because the export credit guarantee takes effect from the start of delivery. If there are several deliveries or if they are also assembled under the export contract, the risk period is usually longer than the financing period. The duration of the risk influences the amount of the coverage costs. ',
  interestResultsTitle: 'Premium for supplier credit coverage with deductible reduced to 5%:',
  required: 'Required field',
  buyerInterest: 'Buyer interest that covers costs',
  gainInterest: 'Interest gain / Interest loss exporter',
  discountRate: 'Please enter the expected discount rate here (ask us for an indication).',
  incomeInterest: 'Here you calculate what interest income or expenses you have with other buyer interest rates.',
  bankInterest: 'Bank interest costs p.a.',
  disagio: 'Disagio (processing fee)',
  disagioInterest: 'Fees (one-time)',
  interestRate: 'Interest rate to supplier',

  disclaimerHermes: 'All our calculators are created to the best of our knowledge and are updated continuously. Nevertheless, they serve only as a guide, results are in no way binding and TraFinScout assumes no liability for the accuracy, timeliness and accuracy. All premiums and costs of Hermes Cover are bindingly determined exclusively by Euler Hermes AG, Export Credit Guarantees of the Federal Republic of Germany',
  fabricationCalculator: 'Fabrication risk cover',
  rechnerCalculator: 'Calculator application and issue fee Hermes cover',
  calculatorFabricationTooltip: 'With fabrication risk coverage, exporters can insure the production costs incurred in connection with an export transaction (prime costs, these must be proven in the event of a claim). <0 /> <0 />'
    + 'Ex-shipment risk cover is available through additional receivables cover (supplier credit cover / buyer credit cover). <0 /><0 />'
    + 'A fee is payable for the assumption of manufacturing risk cover, the amount of which depends essentially on the cost price, the duration of the manufacturing period, the scope of the risks covered (scope of cover) and the country category.',
  fabricationTitle: 'Charge calculator for fabrication risk cover',
  initDate: 'Fabrication start date',
  endDate: 'Fabrication end date',
  fabricationYear: 'Fabrication time',
  coverScope: 'Scope of cover',
  economicRisk: 'Economic and political risks',
  restrictionRisk: 'Restriction to political risks',
  cost: 'Cost amount',
  coverResultTitle: 'Charge for manufacturing risk cover',
  coverLandTooltip: 'If shipping to a third country, either the country category of the ordering country or that of the third country will be used. <0 /><0 />'
    + 'The country category of the third country is applied if it is less favorable than that of the ordering country',
  notPossible: 'Not possible',
  enterData: 'Please enter data',
  applicationTitle: 'Euler Hermes fee calculator',
  applicationTitleTooltip: 'Here you can see at a glance what an application for Hermes cover (application fee) and the execution of the cover document (execution fee) cost.<0 /><0 />'
    + 'If an application for receivables cover (supplier credit cover) can be submitted digitally via Click&Cover Export, these fees are currently waived. <0 /><0 />'
    + 'There are no separate fees for manufacturing risk cover if it is applied for together with receivables cover.<0 /><0 />'
    + 'The application fee is payable regardless of whether a cover note is issued or denied.',
  coverType: 'Cover Type',
  coverTypeTooltip: '<1>Supplier credit cover</1> <0 />'
    + 'Allows German exporting companies to cover receivables from an export transaction <0 /><0 />'
    + '<1>Financial credit cover</1><0 />'
    + 'Allows banks to cover loan receivables resulting from the Financing of a German export transaction<0><0 />'
    + '<1>Factory risk cover</1><0 />'
    + 'Enables German exporters to hedge production costs incurred for export business<0 /><0 />'
    + '<1>Flat-rate export guarantee</ 1><0 />'
    + 'Hedging of short-term receivables (payment terms up to 12 months) German exporters who repeatedly supply several buyers in different countries<0 /><0 />'
    + '<1>Contract guarantee cover</ 1><0 />'
    + 'Protection against losses arising from politically induced or unlawful drawing of the contractual guarantee',
  supplierCreditCover: 'Supplier credit cover',
  buyerCreditCover: 'Buyer credit cover',
  riskCoverFabrication: 'Isolated or combined with a Wholeturnover Policy',
  isolatedContractBondCover: 'Isolated contract bond cover',
  orderValue: 'Order value',
  loanAmount: 'Loan amount',
  feeIncurred: 'Fee to be paid to Euler Hermes',
  applicationFee: 'Application fee',
  executionFee: 'Execution fee',
  costPrice: 'Cost price',
  warrantyAmount: 'Warranty amount',
  notAvailable: 'Not available',

  // Constants ---------
  pleaseFillField: 'Please fill in this box',
  wrongEmailPassword: 'Wrong email or incorrect password!',
  onlyNumber: 'Numbers only',
  onlyLetterAndCharacter: 'Only letters and special characters',
  fileType: 'Upload a correct file format',
  notAllowed: 'Something went wrong.',
};

export default resourceEn;
