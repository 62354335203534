import { gql } from 'apollo-boost';

/**
 * Mutation to log in
 * @param email
 * @param password
 */
export const LOGIN_VERIFICATION = gql`
  mutation login($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password){
      token
      user {
        isAdmin
        hasCompany
        firstName
        lastName
        registerStep
      }
      refreshToken
    }
  }
`;

export const REGISTRATION = gql`
  mutation registration(
    $firstName: String!, 
    $lastName: String!, 
    $email: String!, 
    $company: String!, 
    $password: String!,
    $phoneNumber: String,
    $salutation: String!,
    $termsAndCondition: Boolean!,
    $clientType: Int!,
    $jobTitle: String,
    $marketingPartner: Int,
    ) {
    register(
      input : {
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        company: $company,
        password1: $password,
        password2: $password,
        phoneNumber: $phoneNumber,
        salutation: $salutation,
        termsAndCondition: $termsAndCondition,
        clientType: $clientType,
        jobTitle: $jobTitle,
        marketingPartner: $marketingPartner,
      }
    ) {
      firstName
      errors{
        field
        messages
      }
    }
  }
`;

export const PASSWORD_RESET_LINK = gql`
  mutation resetLink($email: String!) {
    passwordResetLink(
      input: {
        email: $email,
      }
    ) {
      errors {
        field
        messages
      }
      message {
        detail
        messageType
      }
    }
  }
`;

export const PASSWORD_RESET_CHECK = gql`
  mutation reset($uid: String!, $token: String!) {
    passwordResetCheck(
      uid: $uid,
      token: $token
    ) {
      message {
        detail
        messageType
      }
    }
  }
`;

export const PASSWORD_RESET = gql`
  mutation reset($uid: String!, $token: String!, $password: String!) {
    passwordReset(
      input: {
        uid: $uid,
        token: $token,
        password: $password
      }
    ) {
      detail
      errors {
        field
        messages
      }
    }
  }
`;

export const PASSWORD_CHANGE = gql`
  mutation passwordChange($password: String!) {
    lazyPasswordReset(
      input: {
        password: $password
      }
    ) {
      detail
      errors {
        field
        messages
      }
    }
  }
`;

export const CONFIRMATION_EMAIL = gql`
  mutation emailConfirmation($uid: String!, $token: String!) {
    emailConfirmation(
      uid: $uid,
      token: $token,
    ) {
      message {
        detail
        messageType
      }
    }
  }
`;

export const PROFILE = gql`
  mutation profile(
    $id: Int,
    $name: String!,
    $street: String!,
    $zipcode: String!,
    $city: String!,
    $country: String!,
    $industry: String!,
    $coverageNumber: String,
    $employees: String!,
    $revenue: String!,
    $exportRate: String!,
    $exportOrder: String!,
    $rateOfExperience: String!,
    $typeOfCompany: Int!,
    $parent: String
  ) {
    updateCompany(
      input: {
        id: $id,
        name: $name,
        streetAndNumber: $street,
        zipCode: $zipcode,
        city: $city,
        country: $country,
        industry: $industry,
        coverageNumber: $coverageNumber,
        numberOfEmployee: $employees,
        revenueLastYear: $revenue,
        highExportRate: $exportRate,
        exportOrderUsually: $exportOrder,
        rateOfExperience: $rateOfExperience,
        typeOfCompany: $typeOfCompany,
        parent: $parent
      }
    ) {
      streetAndNumber
      name
      errors {
        field
        messages
      }
    }
  }
`;

export const EDIT_PROFILE = gql`
  mutation editProfileMutation(
    $salutation: String,
    $firstName: String,
    $lastName: String,
    $phoneNumber: String,
    $jobTitle: String,
  ) {
    editProfileMutation(
      input: {
        salutation: $salutation,
        firstName: $firstName,
        lastName: $lastName,
        phoneNumber: $phoneNumber,
        jobTitle: $jobTitle,
      }
    ) {
      firstName
      lastName
      salutation
      phoneNumber
      errors {
        field
        messages
      }
    }
  }
`;

export const VERIFY_TOKEN = gql`
mutation Verify($token: String!){
  verifyToken(token: $token)
    {
      payload
    }
}`;

export const SET_USER_LANGUAGES = gql`
mutation SetLanguage($languageCode: String!) {
  setUserLanguage(languageCode: $languageCode) {
    ok
    error {
      field
      messages
    }
  }
}`;

export const REGISTER_NEW_MEMBER = gql`
mutation ($input: RegisterNewMemberInput!){
  registerNewMember(input: $input) {
    errors {
      field
      messages
    }
  }
}`;

export const REGISTER_EXPORTER = gql`
  mutation ($input: AddExporterInput!) {
    addExporter(input: $input) {
      id
      name
      errors {
        field
        messages
      }
    }
  }
`;
