import React, { useContext } from 'react';
import { Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { SiderContext } from '../../../contexts';

const SiderButton = React.memo(() => {
  const siderContext = useContext(SiderContext);

  return (
    <Button
      className="sider-button-content"
      onClick={() => {
        siderContext.setDrawerVisible(true);
        siderContext.setFormDrawerVisible(true);
      }}
    >
      <MenuOutlined />
    </Button>
  );
}, () => true);

export default SiderButton;
