import React, { PureComponent } from 'react';
import {
  Layout,
  Avatar,
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import UserMenu from '../UserMenu';
import Logo from '../Logo';
import SiderButton from './SiderButton';
import routesPaths from '../../routes/routesPaths';
import {
  isUserAdmin,
} from '../../utils/tools';

import { GET_PRODUCT } from '../../graphql/queries/Products';

import { ReactComponent as HomeIcon } from '../../media/icons/icon_home_selected.svg';
import backIcon from '../../media/icons/icon_back_arrow.svg';
import { UserInfoContext } from '../../contexts/UserInfo';
import TranslationSelector from '../TranslationSelector';

import './styles.scss';

const { Header } = Layout;

class HeaderView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: null,
      productName: null,
      productId: null,
      isCompleted: null,
      language: null,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      client,
      location,
      i18n,
    } = this.props;
    const { language } = this.state;
    const pathItems = location.pathname.split('/');
    const isSameLanguage = language === i18n.language;

    if (prevProps.location.pathname !== location.pathname || prevProps.path === '' || !isSameLanguage) {
      this.setState({ language: i18n.language });
      if (pathItems[1] === 'form') {
        client.query({
          query: GET_PRODUCT,
          variables: {
            id: pathItems[2],
          },
        }).then((response) => {
          const categoryName = response.data.getProduct.questionnaireVersion.categoryProduct.name;
          // eslint-disable-next-line no-undef
          document.title = categoryName;
          this.setState({
            categoryName,
            productName: response.data.getProduct.title,
            productId: response.data.getProduct.id,
            isCompleted: response.data.getProduct.isCompleted,
          });
        });
      }
    }
  }

  render() {
    const {
      isLogin,
      setLoginState,
      showSider,
      showLoader,
      path,
      t,
      setSiderState,
    } = this.props;
    const {
      categoryName,
      productName,
      productId,
      isCompleted,
    } = this.state;
    const { state } = this.context;
    const { userInfo: user = {} } = state;
    const splittedPath = path.split('/');
    const showLinkToDeal = isCompleted && !isUserAdmin();
    const [,,, currentView] = splittedPath;

    if (!isLogin && showLoader) return null;

    return (
      ((user && isLogin && currentView !== 'password')) && (
        <Header>
          <Row align="middle" className="h-100">
            {showSider && (
              <div className="sider-button mr-2">
                <SiderButton />
              </div>
            )}
            {currentView === 'form' && (
              <div className="form-drawer-button mr-2">
                <SiderButton />
              </div>
            )}
            <div className={
              (currentView === ''
                || currentView === 'tracking'
                || currentView === 'upload'
                || currentView === 'form'
                || currentView === 'profile'
                || currentView === 'contact'
                || currentView === 'teams')
                ? ((currentView === ''
                || currentView === 'tracking'
                || currentView === 'upload'
                || currentView === 'profile'
                || currentView === 'contact'
                || currentView === 'teams'
                || path === '')
                && 'header-logo')
                || (currentView === 'form' && 'header-logo-fixed') : 'simple-logo'
            }
            >
              <Link to={routesPaths.home} className="logo-responsive">
                <Logo header />
              </Link>
            </div>
            {(currentView === 'request') && (
              <Link to={routesPaths.home} className="to-home">
                <HomeIcon />
                <span>{t('backToHome')}</span>
              </Link>
            )}
            {(currentView === 'form') && (
              [
                <div className="product-title">
                  <div key="user-name" className={`category-name${showLinkToDeal ? ' request-name' : ''}`}>{categoryName}</div>
                  <div className={`product-name ${showLinkToDeal ? ' request-name' : ''}`}>{productName}</div>
                  {showLinkToDeal && (
                    <Link to={`/tracking/${productId}`}>
                      <img src={backIcon} alt="" />
                      <span>{t('backToTracking')}</span>
                    </Link>
                  )}
                </div>,
                <div key="support-key" className="support">
                  <span>{t('requestHelp')}</span>
                  <span>{t('headerPhone')}</span>
                </div>,
              ]
            )}
            <Col className="header-right">
              <div className="header-translator">
                <TranslationSelector />
              </div>
              <div className="avatar-position avatar-hidden">
                <Avatar className="avatar-siderbutton" size={38} icon={<UserOutlined />} />
              </div>
              <div className="ml-2 margin-user-menu" id="userDropdown">
                <UserMenu setLoginState={setLoginState} user={user} setSiderState={setSiderState} />
              </div>
            </Col>
          </Row>
        </Header>
      )
    );
  }
}

HeaderView.contextType = UserInfoContext;

HeaderView.propTypes = {
  isLogin: PropTypes.bool,
  setLoginState: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.object.isRequired,
  showSider: PropTypes.bool.isRequired,
  showLoader: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  t: PropTypes.func,
  setSiderState: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  i18n: PropTypes.object.isRequired,
};

HeaderView.defaultProps = {
  isLogin: false,
  t: () => {},
  setSiderState: () => {},
};

HeaderView.contextType = UserInfoContext;

export default withTranslation()(withRouter(HeaderView));
