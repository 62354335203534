import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import routesPaths from './routesPaths';
import PrivateRoute from './RouteTypes/PrivateRoute';

const routeTypes = {
  public: (props) => {
    const {
      renderProps,
      component: Component,
      isLogin,
      setLoginState,
      setSiderState,
      getPath,
      loading,
    } = props;

    if (isLogin) {
      return (
        <Redirect
          to={routesPaths.home}
        />
      );
    }

    return (
      <Component
        setLoginState={setLoginState}
        setSiderState={setSiderState}
        loading={loading}
        getPath={getPath}
        {...renderProps}
      />
    );
  },
  private: (props) => <PrivateRoute {...props} />,
  persistent: (props) => {
    const {
      renderProps,
      component: Component,
      setLoginState,
    } = props;

    return <Component setLoginState={setLoginState} {...renderProps} />;
  },
};

const MakeRouteWithSubRoutes = (props) => {
  const {
    path,
    exact,
    title,
    component: Component,
    type,
    isLogin,
    setLoginState,
    showBanner,
    setSiderState,
    showLoading,
    loading,
    getPath,
    name,
  } = props;

  return (
    <Route
      path={path}
      exact={exact}
      render={(renderProps) => {
        const propsComponent = {
          showBanner,
          showLoading,
          name,
          ...renderProps,
        };
        // eslint-disable-next-line no-undef
        document.title = title;

        return (
          routeTypes[type]({
            renderProps: propsComponent,
            setLoginState,
            component: Component,
            isLogin,
            loading,
            setSiderState,
            getPath,
          })
        );
      }}
    />
  );
};

MakeRouteWithSubRoutes.propTypes = {
  /**
   * The route path
   */
  path: PropTypes.string.isRequired,
  /**
   * Component to be rendered
   */
  component: PropTypes.elementType.isRequired,
  /**
   * If path will be an exact match
   */
  exact: PropTypes.bool,
  /**
   * Path type private, public, persistent
   */
  type: PropTypes.string,
  /**
   * If user is Logged
   */
  isLogin: PropTypes.bool,
  /**
   * Function to change application state to login
   */
  setLoginState: PropTypes.func.isRequired,
  /**
   * Function to change if sider shows up or not
   */
  setSiderState: PropTypes.func.isRequired,
  /**
   * Function to change header behavior
   */
  getPath: PropTypes.func.isRequired,
  /**
   * Function to show banner notification
   */
  showBanner: PropTypes.func.isRequired,
  /**
   * Function to show loading page
   */
  showLoading: PropTypes.func.isRequired,
  /**
   * Tab Title
   */
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
};

MakeRouteWithSubRoutes.defaultProps = {
  exact: true,
  type: 'private',
  isLogin: false,
  name: undefined,
};

export default MakeRouteWithSubRoutes;
