import { gql } from 'apollo-boost';
import { getQuestionsFragment } from '../../fragments/questions';

export const GET_CATEGORY_PRODUCTS = gql`
  query categoryProducts($exporterId: ID)  {
    allQuestionnaireVersions(exporterId: $exporterId) {
      value: id
      categoryProduct {
        display_name: name 
      }
      stepversionSet {
        value: id
        step {
          display_name: name
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products($companyId: ID) {
    getOnboardingProductRequest(companyId: $companyId) {
      id
      title
      updatedAt
      status
      importer
      createdBySalesPartner
      company {
        id
        name
        administrator {
          email
          firstName
          lastName
        }
      }
      questionnaireVersion {
        id
        stepversionSet {
          id
        }
        categoryProduct{
          id
          name
        }
      }
    }
  }
`;

export const GET_PREVIEW = gql`
  query products ($productId: ID!, $stepVersionId: ID!) {
    getPreview(productId: $productId, stepVersionId: $stepVersionId) {
      productTitle
      categoryProductName
      stepName
      previewDataPayload {
        question
        extraLabel
        answer
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query product ($id: ID!) {
    getProduct(id: $id){
      id
      status
      company {
        id
      }
      availableForExporter
      updatedAt
      title
      isEditable
      isCompleted
      user {
        id
      }
      generalScore {
        stepsAnswered
        numberSteps
        percentage
        done
      }
      questionnaireVersion {
        id
        categoryProduct {
          id
          name
        }
      }
      score {
        stepVersion {
          id
          step {
            id
            name
            required
          }
          numberQuestions
        }
        numberAnswers
        percentage
        done
      }
      answerSet {
        id
        question {
          id
        }
        questionItem {
          id
          questionItemType
        }
        finalValue
        finalDisplayValue
        uploadedFileNames
      }
    }
  }
`;

export const GET_QUESTIONS_BY_PRODUCT_VERSION = gql`
  query questions($id: ID!){
    getStepVersionsByQuestionnaireVersion(questionnaireVersionId: $id) {
      id
      step {
        id
        name
        enable
        helpText
      }
      stepQuestions{
        ...getStepQuestion
      }
    }
  }
  ${getQuestionsFragment}
`;

export const GET_PDF = gql`
  query productPdf($id: ID!, $adminAnswers: Boolean){
    getProductPdf(id: $id, adminAnswers: $adminAnswers)
  }
`;

export const GET_NUMBER_OF_PRODUCTS = gql`
  query getNumberProduct($companyId: ID){
    getNumberProductPerCategory (companyId: $companyId){
      categoryName
      numberProducts
    }
  }
`;

export const GET_DASHBOARD_CARDS = gql`
  query getDashboardCards($companyId: ID) {
    getDashboardCards(companyId: $companyId) {
      name
      primaryColor
      secondaryColor
      linkText
      number
      link
    }
  }
`;
