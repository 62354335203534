import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import Apollo from './wrappers/Apollo';

import 'antd/dist/antd.css';
import './styles/overwrite.scss';
import './styles/base.scss';
import './styles/utils.scss';
import './styles/responsive.scss';

const App = () => (
  <Router data-test="component-router">
    <Apollo />
  </Router>
);

export default App;
