import React, { useContext } from 'react';
import { UserInfoContext } from '../../contexts/UserInfo';
import ClickToAccess from './types/ClickToAccess';
import RouteAccess from './types/RouteAccess';

const restrictionTypeComponent = {
  route: RouteAccess,
  clickTo: ClickToAccess,
};

export const hasPermission = ({ permissions = [], nameList = [] }) => {
  const modulesNames = permissions.map((module = {}) => module.codename);
  const innerList = typeof nameList === 'string' ? [nameList] : nameList;

  if (!innerList.length > 0 || innerList.some((name) => modulesNames.includes(name))) {
    return true;
  }

  return false;
};


const hasAccess = {
  perName: hasPermission,
};

function withPermission(props) {
  const {
    name, type = 'clickTo', accessType = 'perName',
  } = props;

  return (Component) => (incomingProps) => {
    const { state = {} } = useContext(UserInfoContext);
    const { userInfo } = state;

    if (!userInfo) return null;

    const { permissions, isAdmin } = userInfo;

    const access = isAdmin ? true : hasAccess[accessType]({ permissions, nameList: name });
    const AccessComponent = restrictionTypeComponent[type];

    return <AccessComponent hasAccess={access} component={Component} {...incomingProps} />;
  };
}

export function HasPermission(nameList) {
  const { state = {} } = useContext(UserInfoContext);
  const { userInfo } = state;
  const { permissions, isAdmin } = userInfo;

  if (isAdmin) return true;

  return hasPermission({ permissions, nameList });
}

export default withPermission;
