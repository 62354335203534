import React, { Component } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-client';
import { concat, HttpLink, InMemoryCache } from 'apollo-boost';
import LayoutView from '../BaseLayout';
import {
  isNull,
  getAuthTokenName,
  setLoginTokens,
} from '../../utils/tools';
import Authentication from '../../views/Authentication';
import {
  API_URL,
} from '../../constants/globals/settings';
import customFetch from './refresh';
import { VERIFY_TOKEN } from '../../graphql/mutations/Users';
import { DEFAULT_CREDENTIALS } from '../../graphql/queries/Admin';
import permissionRequest from './permission';

import './styles.scss';

const globalAccess = (process.env.REACT_APP_HTTP_OUT && process.env.REACT_APP_HTTP_OUT === 'true') || false;

const baseStates = {
  loading: () => null,
  // eslint-disable-next-line react/jsx-props-no-spreading
  component: (props) => (<LayoutView {...props} />),
  // eslint-disable-next-line react/jsx-props-no-spreading
  authorization: (props) => (<Authentication {...props} />),
};

const client = new ApolloClient({
  link: concat(permissionRequest, new HttpLink({
    uri: API_URL,
    fetch: customFetch,
  })),
  cache: new InMemoryCache(),
});

client.defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
  },
};

const getDefaultCredential = (commonFlow, setSiderState, setLoginState) => {
  if (localStorage.getItem(getAuthTokenName())) {
    commonFlow();
  } else {
    client.query({
      query: DEFAULT_CREDENTIALS,
    }).then((response) => {
      const { adminAuth } = response.data;
      if (adminAuth) {
        const { token, user, refreshToken } = adminAuth;
        setLoginTokens(token, refreshToken);
        setSiderState(false);
        setLoginState(true, () => {
        // eslint-disable-next-line no-undef
          localStorage.setItem('profile', user.hasCompany);
          // eslint-disable-next-line no-undef
          localStorage.setItem('admin', user.isAdmin);
          commonFlow();
        });
      } else {
        commonFlow();
      }
    }).catch((error) => {
      console.log(error);
      commonFlow();
    });
  }
};

class Apollo extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-undef
    const accessToken = localStorage.getItem(getAuthTokenName());

    this.setLoginState = this.setLoginState.bind(this);
    this.setSiderState = this.setSiderState.bind(this);
    this.getPath = this.getPath.bind(this);
    this.updateRender = this.updateRender.bind(this);
    this.state = {
      globalState: true,
      render: 'loading',
      showSider: false,
      isLogin: !isNull(accessToken),
      path: '',
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    const authToken = localStorage.getItem(getAuthTokenName());
    // eslint-disable-next-line no-undef
    const auth = localStorage.getItem('authorization');

    const commonFlow = () => {
      if (isNull(auth) && globalAccess) {
        this.setState({
          render: 'authorization',
        });
      } else if (isNull(authToken)) {
        this.setState({
          render: 'component',
        });
      } else {
        const tempClient = client;
        tempClient.mutate({
          mutation: VERIFY_TOKEN,
          variables: {
            token: authToken,
          },
        }).then(() => {
          this.setState({
            render: 'component',
          });
        }).catch((error) => {
          console.log(error);
          this.setState({
            isLogin: false,
            render: 'component',
          });
        });
      }
    };

    getDefaultCredential(commonFlow, this.setSiderState, this.setLoginState);
  }

  setLoginState(newState, postFunction) {
    this.setState({
      isLogin: newState,
    }, postFunction);
  }

  setSiderState(state) {
    this.setState({
      showSider: state,
    });
  }

  getPath() {
    this.setState({
      // eslint-disable-next-line no-undef
      path: window.location.href,
    });
  }

  updateRender() {
    this.setState({
      render: 'component',
    });
  }

  render() {
    const {
      globalState, isLogin, render, showSider, path,
    } = this.state;
    const componentProps = {
      globalState,
      isLogin,
      showSider,
      path,
      setLoginState: this.setLoginState,
      setSiderState: this.setSiderState,
      getPath: this.getPath,
      updateRender: this.updateRender,
    };

    return (
      <ApolloProvider client={client}>
        {baseStates[render](componentProps)}
      </ApolloProvider>
    );
  }
}

export default Apollo;
