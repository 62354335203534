import {
  AUTH_TOKEN,
  REFRESH_TOKEN,
  API_URL,
} from '../constants/globals/settings';
import MIME_TYPES from '../constants/Mime/types';

/**
 * Funtion to check if a value is undefined.
 * @function isUndefined
 * @param value - Any value that can be undefined.
 * @returns {Boolean}
 */
export const isUndefined = (value) => typeof value === 'undefined';

/**
 * Funtion to check if a value is null.
 * @function isNull
 * @param value - Any value that can be null.
 * @returns {Boolean}
 */
export const isNull = (value) => value === null;

/**
 * Function to manage errors of response
 * @function manageResponseError
 * @param errorData: error data
 * @param values: form values
 * @param form: form
 * @param showBanner: banner message
 */
export const manageResponseError = (errorData, showBanner) => {
  if (isUndefined(errorData) || isNull(errorData)) return;
  let message = '';
  if (errorData.length === 1 && isUndefined(errorData[0].field)) {
    message = errorData[0].message;
  } else if (errorData.length === 1) {
    message = errorData[0].messages[0];
  } else {
    const errors = errorData.map((error) => error.messages[0]);
    message = errors.join('. ');
  }
  message = message.concat('.');
  showBanner({
    message,
    type: 'error',
    dataCy: 'test-banner-alert',
  });
};

/**
 * Function to set login tokens
 * @function setLoginTokens
 * @param accessToken: access token provided by backend
 * @param refreshToken: refresh token provided by backend
 */
export const setLoginTokens = (accessToken, refreshToken) => {
  localStorage.setItem(getAuthTokenName(), accessToken);
  localStorage.setItem(getRefreshTokenName(), refreshToken);
};

/**
 * Function to get tenant name in domain
 * @function getSubName
 * @returns {String}
 */
export const getSubName = () => {
  const { hostname } = window.location;
  const hostnameSections = hostname.split('.');
  const [first] = hostnameSections;
  const [firstApiUrl] = API_URL.split(/[.:]/);
  if (first === firstApiUrl) { return ''; }

  return first;
};

/**
 * Function to get auth token name by tenant domain
 * @function getAuthTokenName
 * @returns {String}
 */
export const getAuthTokenName = () => (`${getSubName()}-${AUTH_TOKEN}`);

/**
 * Function to get auth token name by tenant domain
 * @function getRefreshTokenName
 * @returns {String}
 */
export const getRefreshTokenName = () => (`${getSubName()}-${REFRESH_TOKEN}`);

/**
 * Function to validate if some form value is undefined
 * @function validateUndefinedFields
 * @params values
 * @params exception fields
 * @returns boolean
 */
export const validateUndefinedFields = (values, exceptionFields) => {
  const keys = Object.keys(values);

  return keys.every((key) => exceptionFields[key] || (!isUndefined(values[key]) && values[key] !== ''));
};

/**
 * Function to convert file to base64
 * @function getBase64
 * @params file
 * @params callback (next step)
 */
export const getBase64 = (file, callback) => {
  const reader = new FileReader();
  reader.onload = () => callback(reader.result);
  reader.readAsDataURL(file);
};

/**
 * Function to convert base64 file to blob
 * @param {String} dataURI base64 file
 * @param {String} mimeType extension
 */
export const dataURItoBlob = (dataURI, mimeType = 'pdf') => {
  if (isUndefined(dataURI) || isNull(dataURI)) return undefined;
  const byteString = atob(dataURI);

  const arrayBuffer = new ArrayBuffer(byteString.length);
  const binaryArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i += 1) {
    binaryArray[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: MIME_TYPES[mimeType] });

  return blob;
};

/**
 * Function to convert download base64 file
 * @param {String} dataURI base64 file
 * @param {String} name file name
 */
export const downloadURI = (dataURI, name) => {
  if (isUndefined(dataURI)) return;
  const [mimeType] = name.split('.').slice(-1);
  const blob = dataURItoBlob(dataURI, mimeType);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, name);
  } else {
    const fileURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = fileURL;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(fileURL);
    a.remove();
  }
};

/**
 * Funtion to get user admin status
 */
export const isUserAdmin = () => {
  // eslint-disable-next-line no-undef
  const isAdmin = localStorage.getItem('admin');

  return isAdmin === 'true';
};

/**
 * Function to get errors in questionnaire response
 * @param {String} response
 */
export const getQuestionnaireErrors = (response) => {
  const [payload] = response.answerItemPayload;
  const { errors } = payload;
  const errorGroup = {
    general: response.errors,
    items: isNull(errors) ? [] : errors,
  };

  return errorGroup;
};

/**
 * Function to validate an empty array
 * @param array
 */
export const isEmpty = (array) => array.length === 0;

/**
 * Function to format number (1.234.234,50)
 * @param string number to convert
 */
export const formatNumberWithDots = (num) => {
  if (num) {
    const cleanString = num
      .replace(/\./g, '')
      .replace(',', '.');
    let newValue = cleanString;
    if (!isNaN(parseFloat(cleanString))) {
      newValue = parseFloat(cleanString)
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    return newValue;
  }
};

export const formatFloatWithDots = (num, digits = 4) => {
  if (num) {
    const cleanString = num
      .replace(/\./g, '')
      .replace(',', '.');
    let newValue = cleanString;
    if (!isNaN(parseFloat(cleanString))) {
      newValue = parseFloat(cleanString)
        .toFixed(digits)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    return newValue;
  }
};

export const capitalLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

export const setFormErrors = (form, data) => {
  if (data.errors) {
    const errors = data.errors.map((element) => ({
      name: element.field,
      errors: element.messages,
    }));
    form.setFields(errors);

    return true;
  }

  return false;
};
