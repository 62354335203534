import React, { Component } from 'react';
import { Button, Row, message } from 'antd';
import { withTranslation } from 'react-i18next';
import MiddleCard from '../../components/MiddleCard';
import PreLoginContainer from '../../components/PreLoginContainer';
import AutoForm from '../../components/AutoForm';
import formItems from './form';

import './styles.scss';

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.setAuthenticationValue = this.setAuthenticationValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.form = React.createRef();
  }

  setAuthenticationValue() {
    const { current } = this.form;
    current.submit();
  }

  handleSubmit(values) {
    const { t, updateRender } = this.props;
    if (values.base_password === '1THURRQUATHNaYN8') {
      // eslint-disable-next-line no-undef
      localStorage.setItem('authorization', true);
      updateRender();
    } else {
      message.error(t('wrongPassword'));
    }
  }

  render() {
    const { t } = this.props;
    const cardTitle = t('basicAuthentication');
    const buttonText = t('authorize');

    return (
      <PreLoginContainer>
        <Row type="flex" justify="center" className="mt-5">
          <MiddleCard cardTitle={cardTitle}>
            <AutoForm
              form={this.form}
              className="mt-1"
              itemList={formItems(t)}
              handleSubmit={this.handleSubmit}
            />
            <Button
              type="primary"
              onClick={this.setAuthenticationValue}
              data-cy="test-login-button-loginView"
            >
              {buttonText}
            </Button>
          </MiddleCard>
        </Row>
      </PreLoginContainer>
    );
  }
}

export default withTranslation()(Authentication);
