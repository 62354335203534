import { gql } from 'apollo-boost';

export const DEFAULT_CREDENTIALS = gql`
{
  adminAuth {
    token
    user {
      isAdmin
      hasCompany
      firstName
      lastName
    }
    refreshToken
  }
}`;

export const ADMIN_LOGOUT = gql`
{
  adminLogout
}`;
