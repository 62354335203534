import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import Logo from '../Logo';

const PreLoginContainer = ({ children, className, partner }) => (
  <Row type="flex" align="middle" className={`pre-login-container ${className}`}>
    <Row className="w-100">
      <Logo partner={partner} />
      {children}
    </Row>
  </Row>
);

PreLoginContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  partner: PropTypes.string,
};

PreLoginContainer.defaultProps = {
  className: '',
  partner: null
};

export default PreLoginContainer;
