import { ReactComponent as LogoutIcon } from '../../media/icons/logout_icon.svg';
import { ReactComponent as ProfileIcon } from '../../media/icons/icon_edit_profile.svg';

const userMenuItems = (t) => [
  {
    title: t('editProfile'),
    key: 'profile',
    icon: ProfileIcon,
  },
  {
    title: t('logout'),
    key: 'logout',
    icon: LogoutIcon,
  },
];

export default userMenuItems;
