import { gql } from 'apollo-boost';

export const PROFILE_OPTIONS = gql`
  query getProfileOptions(
    $industriesEnum: String!,
    $employeesEnum: String!,
    $revenuesEnum: String!,
    $exportRatesEnum: String!,
    $exportOrderEnum: String!,
    $experienceRateEnum: String!,    
    $companySalesIndustryEnum: String!,    
    ) {
    industry:  __type(name: $industriesEnum) {
      name
      enumValues {
        value: name
        display_name: description 
      }
    }
    numberEmployee: __type(name: $employeesEnum) {
      name
      enumValues {
        value: name
        display_name: description
      }
    }
    revenueLastYear: __type(name: $revenuesEnum) {
      name
      enumValues {
        value: name
        display_name: description
      }
    }
    highExportRate: __type(name: $exportRatesEnum) {
      name
      enumValues {
        value: name
        display_name: description
      }
    }
    exportOrderUsually: __type(name: $exportOrderEnum) {
      name
      enumValues {
        value: name
        display_name: description
      }
    }
    rateOfExperience: __type(name: $experienceRateEnum) {
      name
      enumValues {
        value: name
        display_name: description
      }
    }
    salesIndustry: __type(name: $companySalesIndustryEnum) {
      name
      enumValues {
        value: name
        display_name: description
      }
    }
  }
`;

export const USER_INFO = gql`
  query user {
    userInfo {
      id
      email
      language {
        languageCode
        language
      }
      firstName
      lastName
      phoneNumber
      salutation
      hasCompany
      hasManyCompanies
      registerStep
      jobTitle
      company {
        id
        name
        streetAndNumber
        exporterNumber
        zipCode
        city
        country {
          id
          country
        }
        industry
        isPartOfGroup
        nameCompanyGroup
        numberOfEmployee
        revenueLastYear
        highExportRate
        exportOrderUsually
        rateOfExperience
        coverageNumber
      }
    }
  }
`;

export const GET_CLIENT_TYPES = gql`
  query {
    getClientTypes {
      id
      name
    }
}`;

export const GET_CHECK_COMPANY = gql`
  query checkCompany ($name: String!) {
    checkCompanyName(name: $name) {
      exists
    }
  }
`;

export const USER_INFO_CONTEXT = gql`
  query user($companyId: ID) {
    userInfo {
      id
      email
      language {
        languageCode
        language
      }
      isAdmin
      salesPartnerApproved
      firstName
      lastName
      phoneNumber
      permissions {
        codename
      }
      salutation
      hasCompany
      hasManyCompanies
      clientType
      registerStep
      company {
        isSalesPartnerCompany
        parent {
          name
        }
        typeOfCompany
        id
        name
        streetAndNumber
        zipCode
        city
        country {
          id
          country
        }
        industry
        isPartOfGroup
        nameCompanyGroup
        numberOfEmployee
        revenueLastYear
        highExportRate
        exportOrderUsually
        rateOfExperience
        coverageNumber
        typeSalesCompany
        isSalesPartnerCompany
      }
    }

    myCompany (companyId: $companyId){
      id
      name
      isSalesPartnerCompany
      typeOfCompany
      typeOfCompanyDisplay
      typeSalesCompany
      editorsNumber
      exporterNumber
      salesPartnerNumber
      salesIndustry
      subsidiaryCompaniesNumber
      createdAt
      streetAndNumber
      zipCode
      industry
      city
      country {
        id
        country
      }
      isPartOfGroup
      nameCompanyGroup
      numberOfEmployee
      revenueLastYear
      highExportRate
      exportOrderUsually
      rateOfExperience
      coverageNumber
      administrator {
        firstName
        lastName
        email
        firstName
        lastName
      }
      parent {
        name
      }
    }
  }
`;

export const CHECK_NEW_MEMBER = gql`
  query checkRegisterNewMember ($uid: String!, $token: String!) {
    checkRegisterNewMember(uid: $uid, token: $token) {
      user {
        email
        clientType
        salutation
        jobTitle
        firstName
        lastName
        phoneNumber
        company {
          name
        }
      }
      message {
        detail
        messageType
      }
    }
  }
`;

export const GET_SUBSIDIARY_COMPANIES = gql`
  query getSubsidiaryCompanies ($companyId: ID!, $includeParentCompany: Boolean) {
    getSubsidiaryCompanies (companyId: $companyId, includeParentCompany: $includeParentCompany) {
      id
      name
      administrator {
        email
        firstName
        lastName
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query {
    getCompanies {
      id
      name
      administrator {
        email
        firstName
        lastName
      }
    }
  }
`;
