import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import errorIcon from '../../media/icons/icon.svg';
import successIcon from '../../media/icons/sucess_icon_banner.svg';
import closeIcon from '../../media/icons/close_icon.svg';

const iconType = {
  error: errorIcon,
  success: successIcon,
  warning: errorIcon,
};

const BannerAlert = (props) => {
  const {
    showBanner, message, type, onClose, dataCy,
  } = props;

  if (!showBanner) return null;

  return (
    <div id="alert">
      <Alert
        closeText={
          <img src={closeIcon} alt="close-icon" />
        }
        icon={
          <img src={iconType[type]} alt="banner-icon" />
        }
        type={type}
        banner
        message={message}
        data-cy={dataCy}
        afterClose={onClose}
        closable
      />
    </div>
  );
};

BannerAlert.propTypes = {
  message: PropTypes.string.isRequired,
  showBanner: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dataCy: PropTypes.string,
};

export default BannerAlert;
