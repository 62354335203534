import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';
import { USER_INFO_CONTEXT } from '../../graphql/queries/Users';
import {
  getAuthTokenName,
  isNull,
} from '../../utils/tools';
import { COMPANY } from '../../constants/globals/settings';

export const UserInfoContext = React.createContext();

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isLogin: PropTypes.bool,
};

const defaultProps = {
  isLogin: false,
};

const initialState = {
  userInfo: undefined,
  company: undefined,
  companyId: undefined,
};

const actions = {
  default: () => (initialState),
  updateInfo: (action) => ({
    userInfo: action.data.userInfo,
    company: action.data.company,
    companyId: action.data.companyId,
  }),
  refetchInfo: (action, state, refetch) => {
    let innerCompanyRequest = state.companyId;
    const innerState = { ...state };

    if (action.data && action.data.companyId) {
      innerCompanyRequest = action.data.companyId;
      innerState.companyId = innerCompanyRequest;
    }

    refetch({ companyId: innerCompanyRequest, variables: { companyId: innerCompanyRequest } });

    return innerState;
  },
  clearInfo: () => (initialState),
};

function UserInfoContextProvider(props) {
  const { children, isLogin } = props;

  const [meRequest, {
    data, refetch, called, error,
  }] = useLazyQuery(USER_INFO_CONTEXT, { fetchPolicy: 'no-cache' });
  const reducer = (state, action = 'default') => actions[action.type](action, state, called ? refetch : meRequest);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const authToken = localStorage.getItem(getAuthTokenName());
    const companyId = localStorage.getItem(COMPANY);
    if (authToken && isLogin) {
      meRequest({ variables: { companyId: companyId || undefined } });
    }
  }, [meRequest, isLogin]);

  useEffect(() => {
    const authToken = localStorage.getItem(getAuthTokenName());
    if (data && authToken) {
      const companyId = data.myCompany ? data.myCompany.id : undefined;

      dispatch({
        type: 'updateInfo',
        data: {
          userInfo: data.userInfo,
          company: data.myCompany || {},
          companyId,
        },
      });
      if (isNull(localStorage.getItem('userId'))) {
        localStorage.setItem('userId', data.userInfo.id);
      }
      if (companyId) {
        localStorage.setItem(COMPANY, companyId);
      }
    }
  }, [data, error]);

  return (
    <UserInfoContext.Provider value={{ state, dispatch }}>
      {children}
    </UserInfoContext.Provider>
  );
}

UserInfoContextProvider.propTypes = propTypes;
UserInfoContextProvider.defaultProps = defaultProps;

export default UserInfoContextProvider;
