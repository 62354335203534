import React, { Component } from 'react';
import {
  Row,
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  TimePicker,
  Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import {
  DATE_FORMAT,
  MONTHLY_FORMAT,
  TIME_FORMAT_GERMANY,
} from '../../constants/globals/settings';
import PasswordInput from './PasswordInput';
import { isUndefined } from '../../utils/tools';

import './styles.scss';


const { Option } = Select;
const { TextArea } = Input;
const { MonthPicker } = DatePicker;

const areaType = (item) => (
  <TextArea
    placeholder={item.placeholder}
    rows={item.rows}
  />
);

const inputType = (item) => (
  <Input
    autoFocus={item.autoFocus}
    size="large"
    placeholder={item.placeholder}
    data-cy={item.dataCy}
    onKeyDown={item.onKeyDown}
    onBlur={item.onBlur}
    onChange={item.onChange}
    disabled={item.disabled}
    value={item.value}
  />
);

const passwordType = (item) => (
  <PasswordInput
    name={item.name}
    size="large"
    placeholder={item.placeholder}
    dataCy={item.dataCy}
  />
);

const selectType = (item) => (
  <Select
    size="large"
    placeholder={item.placeholder}
    /* eslint-disable-next-line no-undef */
    getPopupContainer={() => document.getElementById('form')}
    onChange={item.onChange}
    onSearch={item.onSearch}
    disabled={item.disabled}
    defaultActiveFirstOption={item.activeFirst}
    showSearch
    filterOption={(inputValue, option) => {
      const regex = new RegExp(inputValue, 'i');
      return regex.test(option.props.children);
    }}
  >
    {
      item.selection.map((opt) => (
        <Option
          key={`opt-${opt.value}`}
          value={opt.value}
          disabled={opt.disabled}
        >
          {opt.display_name}
        </Option>
      ))
    }
  </Select>
);

const timeType = (item) => (
  <TimePicker
    size="large"
    className="w-100"
    defaultValue={item.defaultDate}
    format={TIME_FORMAT_GERMANY}
    placeholder={item.placeholder}
    disabled={item.disabled}
  />
);

const dateType = (item) => (
  <DatePicker
    size="large"
    className="w-100"
    popupStyle={item.popupStyle}
    format={DATE_FORMAT}
    placeholder={item.placeholder}
    defaultPickerValue={item.defaultDate}
    disabled={item.disabled}
    disabledDate={item.disabledDates}
  />
);

const inputNumberType = (item) => (
  <InputNumber
    size="large"
    placeholder={item.placeholder}
    data-cy={item.dataCy}
    disabled={item.disabled}
  />
);

const monthlyType = (item) => (
  <MonthPicker
    size="large"
    className="w-100"
    format={MONTHLY_FORMAT}
    placeholder={item.placeholder}
    defaultPickerValue={item.defaultDate}
    disabled={item.disabled}
  />
);

const checkboxType = (item) => (
  <Checkbox
    onChange={item.onChange}
  >
    {item.displayName}
  </Checkbox>
);

const textType = (item) => (
  <span className={item.className}>{item.display_name}</span>
);

const radioTypes = {
  input: inputType,
  text: textType,
};

const radioGroupType = (item) => (
  <Radio.Group
    onChange={item.onChange}
    disabled={item.disabled}
  >
    {
      item.selection.map((option) => (
        <Radio
          key={option.value}
          value={option.value}
        >
          <div className="radio-option">
            <span className={option.isContact ? 'contact-label' : ''}>{option.display_name}</span>
            {option.elem && radioTypes[`${option.elem.type}`](option.elem)}
          </div>
        </Radio>
      ))
    }
  </Radio.Group>
);

const multipleInputType = (item) => (
  <Select
    mode="tags"
  />
);

const fieldTypes = {
  input: inputType,
  text: textType,
  select: selectType,
  checkbox: checkboxType,
  datePicker: dateType,
  timePicker: timeType,
  inputNumber: inputNumberType,
  area: areaType,
  monthly: monthlyType,
  password: passwordType,
  radioGroup: radioGroupType,
  multipleInput: multipleInputType,
};

class AutoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: i18n.language,
    };
    this.getColumn = this.getColumn.bind(this);
  }

  componentDidUpdate() {
    const { language } = this.state;
    const { form } = this.props;

    if (language !== i18n.language && !isUndefined(form)) {
      const { current = {} } = form;
      current.validateFields();

      this.setState({
        language: i18n.language,
      });
    }
  }

  getColumn(item, index) {
    const customRules = item.rules;
    const { onClear } = item;
    const refInput = fieldTypes[`${item.type || 'input'}`](item);
    const { t } = this.props;
    const { formStructure } = this.props;
    const hasValue = formStructure ? formStructure.getFieldValue(item.name) : false;


    return (
      <Form.Item
        key={index}
        name={item.name}
        label={item.label
        && ([
          <div key="item" className={`ant-form-item-label-${item.disabled ? 'disabled' : ''}`}>
            {item.label}
          </div>,
          onClear && hasValue && (
          <span
            key="clearItem"
            className="clear-button"
            onClick={() => {
              const values = { ...formStructure.getFieldsValue() };
              values[item.name] = undefined;
              formStructure.setFieldsValue(values);
              onClear(values);
            }}
          >
            {t('clear')}
          </span>
          ),
        ])}
        colon={false}
        validateStatus={item.validateStatus}
        validateTrigger={item.type === 'select' ? ['onBlur', 'onSelect'] : 'onChange'}
        rules={customRules}
        valuePropName={item.type === 'checkbox' ? 'checked' : 'value'}
      >
        {refInput}
      </Form.Item>
    );
  }

  render() {
    const {
      itemList,
      className,
      initialValues,
      onValuesChange,
      handleSubmit,
      handleErrorSubmit,
      form,
      formStructure,
      name,
    } = this.props;

    return (
      <Row id="form" className={className}>
        <Form
          name={name}
          form={formStructure}
          ref={form}
          hideRequiredMark
          labelAlign="left"
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          onFinish={handleSubmit}
          onFinishFailed={handleErrorSubmit}
        >
          {
            itemList.map((rowFields) => (rowFields
              && (
              <div key={`Row${rowFields[0].name}`} className="row-with-form-item">
                {
                  rowFields.map((item, index) => (
                    this.getColumn(item, index)
                  ))
                }
              </div>
              )
            ))
          }
        </Form>
      </Row>
    );
  }
}

AutoForm.propTypes = {
  onValuesChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleErrorSubmit: PropTypes.func,
  itemList: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ])).isRequired,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  formStructure: PropTypes.object,
  name: PropTypes.string,
  t: PropTypes.func,
};

AutoForm.defaultProps = {
  className: '',
  name: undefined,
  initialValues: {},
  onValuesChange: null,
  handleErrorSubmit: null,
  formStructure: undefined,
  form: undefined,
  handleSubmit: () => {},
  t: () => {},
};

export default withTranslation()(AutoForm);
