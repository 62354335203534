import React from 'react';
import {
  Layout,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FinciteLogo from '../FinciteLogo';
import './styles.scss';

const { Footer } = Layout;

const dataProtectionLink = 'https://trafinscout.com/datenschutz';
const impressumLink = 'https://trafinscout.com/impressum';

const FooterPage = (props) => {
  const { t } = useTranslation()
  const { isLogin } = props;

  if (isLogin) return null;

  return (
    <Footer className="footer">
      <FinciteLogo />
      <div className="page-options">
        <div>
          |
          <a href={dataProtectionLink} target="_blank" rel="noopener noreferrer">{t('privacy')}</a>
        </div>
        <div className="ml-3">
          |
          <a href={impressumLink} target="_blank" rel="noopener noreferrer">{t('imprint')}</a>
        </div>
      </div>
    </Footer>
  );
};

FooterPage.propTypes = {
  isLogin: PropTypes.bool,
};

FooterPage.defaultProps = {
  isLogin: false,
};

export default FooterPage;
