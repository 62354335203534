import React from 'react';
import {
  Card,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import colCard from './responsive';

const MiddleCard = (props) => {
  const {
    children, cardTitle, extraFooter, className,
  } = props;

  return (
    <Col {...colCard} className={`${className}`}>
      <Card
        title={cardTitle}
        key="middleCard"
      >
        {children}
      </Card>
      {extraFooter}
    </Col>
  );
};

MiddleCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  cardTitle: PropTypes.string,
  extraFooter: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

MiddleCard.defaultProps = {
  cardTitle: undefined,
  extraFooter: undefined,
  className: '',
};

export default MiddleCard;
