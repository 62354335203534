import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import logo from '../../media/images/trafinsLogoSVG.svg';
import marketingPartnersData from '../../constants/globals/marketingPartners';
//import logoLarge from '../../media/images/trafinsLogoSVG.svg';
import './styles.scss';
import { isNull } from '../../utils/tools';

const Logo = React.memo(({ header, partner }) => (
  <Row className="text-center">
    <img className={header ? 'responsive-header-logo' : 'responsive-logo'} src={logo} alt="logo" />
      {
          !isNull(partner) && <img src={marketingPartnersData[partner].logo} alt="partner" className="hrp-log" />
      }

  </Row>
));

Logo.propTypes = {
  header: PropTypes.bool,
  partner: PropTypes.string,
};

Logo.defaultProps = {
  header: false,
  partner: null
};

export default Logo;
