import { gql } from 'apollo-boost';

const questionFragment = gql`
  fragment baseStepQuestion on StepQuestionType {
    id
    enable
    hideInFront
    question {
      id
      label
      helpText
      tooltip
      sendEmail
      isOptional
      dependencies {
        enableDependency {
          name
          value
        }
        showDependency {
          name
          value
        }
      }
      questionItems {
        id
        enable
        required
        questionItemType
        hint
        placeholder
        label
        validator {
          regularExpression
          errorMessage
        }
        option {
          categoryChoice {
            choices {
              id
              name
              preselected
              helpText
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getQuestionsFragment = gql`
  fragment getStepQuestion on StepQuestionType {
    ...baseStepQuestion
    children{
      ...baseStepQuestion
      children{
        ...baseStepQuestion
        children{
          ...baseStepQuestion
        }
      }
    }
  }
  ${questionFragment}
`;