import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Menu,
  Row,
  Badge,
} from 'antd';
import { withTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/react-hooks';
import Logo from '../../Logo';

import './styles.scss';

import phoneIcon from '../../../media/icons/icon_phone.svg';
import { UserInfoContext } from '../../../contexts/UserInfo';
import { isNull, isUndefined } from '../../../utils/tools';
import { GET_TEAM_NOTIFICATION } from '../../../graphql/queries/Profile';
import { RESTART_NOTIFICATION_NUMBER } from '../../../graphql/mutations/Profile';
import { USER_TYPES } from '../../../constants/globals/settings';
import withPermission from '../../../HOC/withPermission';
import TooltipText from '../../TooltipText';


const { SubMenu } = Menu;

const SiderContent = ({ t, siderMenus, history }) => {
  const { state } = useContext(UserInfoContext);
  const [isEditor, setIsEditor] = useState(3);
  const [alertNumber, setAlertNumber] = useState(0);
  const client = useApolloClient();
  const isAdmin = localStorage.getItem('admin') === 'true';

  // eslint-disable-next-line no-undef
  const path = window.location.pathname.split('/');
  let selectedPath = [siderMenus[0].key];
  if (path.length > 1 && path[1] !== '') {
    selectedPath = [path[1]];
  }
  if (path.length > 2) {
    selectedPath = [path[2]];
  }

  useEffect(() => {
    const { userInfo = {} } = state;
    if (!isUndefined(userInfo)) {
      setIsEditor(userInfo.clientType);
      if (!isNull(userInfo.company) && !isUndefined(userInfo.company)) {
        client.query({
          query: GET_TEAM_NOTIFICATION,
          variables: {
            companyId: userInfo.company.id,
          },
        }).then((response) => {
          const { data } = response;
          if (data.getTeamManagementNotification) {
            const { notificationNumber } = data.getTeamManagementNotification;
            setAlertNumber(notificationNumber);
          }
        });
      }
    }
  }, [state, client]);

  const restartNotifications = () => {
    const { userInfo } = state;
    client.mutate({
      mutation: RESTART_NOTIFICATION_NUMBER,
      variables: {
        companyId: userInfo.company.id,
      },
    }).then((response) => {
      const { data } = response;
      const { success } = data.restartTeamManagementNotification;
      if (success) { setAlertNumber(0); }
    });
  };

  return (
    <Menu
      mode="inline"
      openKeys={path.length > 2 ? [path[1]] : [siderMenus[1].key]}
      selectedKeys={selectedPath}
      style={{ height: '100%', borderRight: 0 }}
    >
      <div className="logo-sider"><Logo header /></div>
      {
      siderMenus.map((menu) => {
        const { userInfo = {} } = state;
        const MenuContainer = withPermission({ name: menu.permissionName })(Menu.Item);
        const hideItem = (menu.title === t('teamsManagement'))
          && ((isEditor === USER_TYPES.teamMember) || (isAdmin && !userInfo.company));
        const checkRender = (
          menu.subMenu
            ? (
              <SubMenu
                key={menu.key}
                title={(
                  <Row type="flex" align="middle">
                    <menu.icon className="mr-2" />
                    <TooltipText text={menu.title} customClasses="menu-title" />
                  </Row>
                )}
              >
                {
                  menu.subMenu.map((subMenu) => (
                    <Menu.Item
                      key={subMenu.key}
                      onClick={() => {
                        history.push(subMenu.redirection);
                      }}
                    >
                      <span className="submenu-title">{subMenu.title}</span>
                    </Menu.Item>
                  ))
                }
              </SubMenu>
            )
            : (
              <MenuContainer
                key={menu.key}
                onClick={() => {
                  if (menu.title === t('teamsManagement')) {
                    restartNotifications();
                  }
                  history.push(menu.redirection);
                }}
              >
                <Row type="flex" align="middle">
                  <menu.icon className="mr-2" />
                  <TooltipText text={menu.title} customClasses="menu-title" />
                  {(alertNumber > 0) && (menu.title === t('teamsManagement')) && (
                    <div className="alert-team-member">
                      {alertNumber}
                    </div>
                  )}
                  {menu.key === 'inbox' ? <Badge count={1} /> : null}
                </Row>
              </MenuContainer>
            )
        );
        if (hideItem) {
          return <div key="hide-item" />;
        }
        return checkRender;
      })
    }
      <div className="pr-2 pl-3 bt-mystic">
        {/* <div className="pb-3 pt-4">
          <Button type="primary">
            <PlusCircleOutlined />
            Neue Kalkulation
          </Button>
        </div> */}
      </div>
      <div className="contact-phone">
        <div><img src={phoneIcon} alt="" /></div>
        <div className="phone-number">
          <div className="text-phone1">{t('weAreHereForYou')}</div>
          <div className="text-phone2">{t('weAreHereForYouExample')}</div>
        </div>
      </div>
    </Menu>
  );
};

SiderContent.propTypes = {
  t: PropTypes.func.isRequired,
  siderMenus: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default withRouter(withTranslation()(SiderContent));
