import { notification } from 'antd';
import { onError } from 'apollo-link-error';

const permissionError = 'You do not have permission to perform this action';

const permissionRequest = onError(
  (response) => {
    const {
      graphQLErrors, networkError, response: innerResponse,
    } = response;
    if (graphQLErrors) {
      const [firstError] = graphQLErrors;

      if (firstError.message === permissionError) {
        innerResponse.errors = null;
        notification.error({ key: 'permissionDenied', duration: 0, message: firstError.message });
      }
    }
    if (networkError) {
      console.log('Network Error');
    }
  },
);

export default permissionRequest;
