import React from 'react';
import BannerAlert from '../BannerAlert';

const withBanner = (WrappedComponent) => class extends React.Component {
  constructor(props) {
    super(props);
    this.showBanner = this.showBanner.bind(this);
    this.onCloseBanner = this.onCloseBanner.bind(this);
    this.fadeoutEffect = this.fadeoutEffect.bind(this);
    this.state = {
      renderBanner: false,
      message: '',
      type: 'success',
      dataCy: '',
      showTimer: null,
      effectInterval: null,
    };
  }

  onCloseBanner() {
    const { showTimer, effectInterval } = this.state;
    clearTimeout(showTimer);
    clearInterval(effectInterval);

    this.setState({
      renderBanner: false,
    });
  }

  fadeoutEffect() {
    const fadeTarget = document.getElementById('alert');
    let count = 0;
    if (fadeTarget) {
      const fadeoutEffect = setInterval(() => {
        if (count < 10) {
          if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = 1;
          }
          if (fadeTarget.style.opacity > 0) {
            fadeTarget.style.opacity -= 0.1;
          }
          count += 1;
        } else {
          this.onCloseBanner();
        }
      }, 30);
      this.setState({
        effectInterval: fadeoutEffect,
      });
    }
  }

  showBanner({ message, type, dataCy }) {
    this.onCloseBanner();
    const timer = setTimeout(() => {
      this.fadeoutEffect();
    }, 5000);

    this.setState({
      renderBanner: true,
      showTimer: timer,
      message,
      type,
      dataCy,
    });
  }

  render() {
    const {
      renderBanner, message, type, dataCy,
    } = this.state;
    const defaultMessage = 'Something was wrong';

    return (
      [
        <BannerAlert
          showBanner={renderBanner}
          message={message || defaultMessage}
          type={type}
          key="bannerAlert"
          onClose={this.onCloseBanner}
          dataCy={dataCy}
        />,
        <WrappedComponent key="withBannerComponent" showBanner={this.showBanner} {...this.props} />,
      ]
    );
  }
};

export default withBanner;
