const permissionNames = {
  addProduct: 'add_product',
  addEditor: 'add_editor',
  addSubsidiaryCompany: 'add_subsidiary_company',
  addSalesPartner: 'add_salespartner',
  viewProduct: 'view_product',
  viewDealTracking: 'view_dealtracking',
  viewDocument: 'view_document',
  addContact: 'add_contact',
  fillQuestionnaire: 'fill_questionnaire',
  submitQuestionnaire: 'submit_questionnaire',
  downloadDocument: 'download_document',
  uploadDocument: 'upload_document',
  viewEditor: 'view_editor',
  removeEditor: 'remove_editor',
  viewSubsidiaryCompany: 'view_subsidiary_company',
  removeSubsidiaryCompany: 'remove_subsidiary_company',
  viewSalesPartner: 'view_salespartner',
  createSalesPartner: 'add_salespartner',
  removeSalesPartner: 'remove_sales_partner',
  viewExporter: 'view_exporter',
  addExporter: 'add_exporter',
  changeExporter: 'change_exporter',
  removeExporter: 'remove_exporter',
  sendExporter: 'send_exporter',
  viewCompany: 'view_company',
  editExporterCompany: 'change_exportercompany',
  editSalesCompany: 'change_salescompany',
};

export default permissionNames;
