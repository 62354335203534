import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useMutation,
  useQuery,
} from '@apollo/react-hooks';
import { GET_LANGUAGES } from '../../graphql/queries/Translation';
import { APP_LANGUAGE, BACKEND_FRONTEND_LANGUAGES } from '../../constants/globals/settings';
import { SET_USER_LANGUAGES } from '../../graphql/mutations/Users';
import { getAuthTokenName } from '../../utils/tools';
import { UserInfoContext } from '../../contexts/UserInfo';

import './styles.scss';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

function TranslationSelector(props) {
  const { state: userData } = useContext(UserInfoContext);
  const { data, loading, error } = useQuery(GET_LANGUAGES);
  const [setUserLanguage] = useMutation(SET_USER_LANGUAGES);
  const { className } = props;
  const { i18n } = useTranslation();

  useEffect(() => {
    if (userData.userInfo) {
      const { language } = userData.userInfo;
      localStorage.setItem(APP_LANGUAGE, language.languageCode);
      i18n.changeLanguage(BACKEND_FRONTEND_LANGUAGES[language.languageCode]);
    }
  }, [userData, i18n]);

  const onClickLanguage = (language) => {
    if (i18n.language === language.frontCode) return;

    if (localStorage.getItem(getAuthTokenName())) {
      setUserLanguage({ variables: { languageCode: language.languageCode } }).then(() => {
        localStorage.setItem(APP_LANGUAGE, language.languageCode);
        i18n.changeLanguage(language.frontCode);
      });
    } else {
      localStorage.setItem(APP_LANGUAGE, language.languageCode);
      i18n.changeLanguage(language.frontCode);
    }
  };

  if (loading || error || !data) return null;

  const languages = data.getLanguages.map(
    (element) => ({ ...element, frontCode: BACKEND_FRONTEND_LANGUAGES[element.languageCode] }),
  );
  languages.reverse();
  const [first, second] = languages;

  return (
    <Row className={`translation-selector ${className}`}>
      <Col
        className={`language first-border ${i18n.language === first.frontCode && 'selected'}`}
        span={12}
        onClick={() => { onClickLanguage(first); }}
      >
        {first.frontCode}
      </Col>
      <Col
        className={`language ${i18n.language === second.frontCode && 'selected'}`}
        span={12}
        onClick={() => { onClickLanguage(second); }}
      >
        {second.frontCode}
      </Col>
    </Row>
  );
}

TranslationSelector.propTypes = propTypes;
TranslationSelector.defaultProps = defaultProps;

export default TranslationSelector;
