import { ReactComponent as ContactIcon } from '../../media/icons/icon_contact.svg'; /** global: AnalyseIcon */
import { ReactComponent as TrackingIcon } from '../../media/icons/icon_tracking.svg'; /** global: TrendsIcon */
import { ReactComponent as UploadIcon } from '../../media/icons/icon_upload.svg'; /** global: ValuationIcon */
import { ReactComponent as HomeIcon } from '../../media/icons/icon_home_selected.svg'; /** global: ValuationIcon */
import { ReactComponent as CalculationIcon } from '../../media/icons/icon_calculation.svg'; /** global: ValuationIcon */
import { ReactComponent as FAQIcon } from '../../media/icons/message.svg'; /** global: ValuationIcon */
import { ReactComponent as MessageIcon } from '../../media/icons/faq.svg'; /** global: ValuationIcon */
import { ReactComponent as CalculatorIcon } from '../../media/icons/calculator.svg'; /** global: ValuationIcon */
import routesPaths from '../../routes/routesPaths';
import permissionNames from '../../constants/globals/permissions';

const siderMenus = (t) => [
  {
    title: 'Home',
    key: 'home',
    icon: HomeIcon,
    redirection: routesPaths.home,
  },
  {
    title: t('teamsManagement'),
    key: 'teams',
    icon: CalculationIcon,
    redirection: routesPaths.teams,
    disabled: true,
    permissionName: [
      permissionNames.viewEditor,
      permissionNames.viewExporter,
      permissionNames.viewSalesPartner],
  },
  {
    title: t('dealTracking'),
    key: 'tracking',
    icon: TrackingIcon,
    redirection: routesPaths.tracking,
    permissionName: permissionNames.viewDealTracking,
  },
  {
    title: t('toUploadDocument'),
    key: 'document',
    icon: UploadIcon,
    redirection: routesPaths.uploadDocument,
    permissionName: permissionNames.viewDocument,
  },
  {
    title: t('calculator'),
    key: 'calculators',
    icon: CalculatorIcon,
    redirection: routesPaths.calculators,
  },
  {
    title: t('contactHelp'),
    key: 'contact',
    icon: ContactIcon,
    redirection: routesPaths.contact,
    permissionName: permissionNames.addContact,
  },
  {
    title: t('faqTitle'),
    key: 'faq',
    icon: FAQIcon,
    redirection: routesPaths.faq,
  },
  {
    title: t('hermesCover'),
    key: 'check',
    icon: MessageIcon,
    redirection: routesPaths.hermesCover,
  },
];

export default siderMenus;
