import React from 'react';
import {
  Tooltip,
} from 'antd';
import PropTypes from 'prop-types';


function TooltipText({ text, customClasses }) {
  const textComponentRef = React.useRef(null);
  const [tooltipTitle, setTooltipTitle] = React.useState('');

  React.useEffect(() => {
    if (
      textComponentRef.current
      && textComponentRef.current.offsetWidth
      < textComponentRef.current.scrollWidth
    ) {
      setTooltipTitle(text);
    }
  }, [text]);

  return (
    <Tooltip title={tooltipTitle}>
      <span className={customClasses} ref={textComponentRef}>
        {text}
      </span>
    </Tooltip>
  );
}

TooltipText.propTypes = {
  text: PropTypes.string.isRequired,
  customClasses: PropTypes.string,
};

TooltipText.defaultProps = {
  customClasses: '',
};

export default TooltipText;
