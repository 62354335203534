import React from 'react';
import { Switch } from 'react-router-dom';
import { ApolloConsumer } from '@apollo/react-hooks';
import {
  Layout,
} from 'antd';
import PropTypes from 'prop-types';
import MakeRouteWithSubRoutes from '../../routes';
import routes from '../../routes/routerList';
import FooterPage from '../../components/Footer';
import withBanner from '../../components/withBanner';
import withLoading from '../../components/withLoading';
import SiderProvider from '../../contexts';
import Header from '../../components/Header';
import Sider from '../../components/Sider';
import './styles.scss';
import TranslationSelector from '../../components/TranslationSelector';
import UserInfoContextProvider from '../../contexts/UserInfo';

const LayoutView = React.memo((props) => {
  const {
    isLogin,
    showSider,
    path,
    loading,
  } = props;

  return (
    <UserInfoContextProvider isLogin={isLogin}>
      <Layout className="base">
        {!showSider && <TranslationSelector className="no-login-translator" />}
        <SiderProvider>
          <ApolloConsumer>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {(client) => (
              <Header
              /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...props}
                showSider={showSider}
                showLoader={loading}
                client={client}
                path={path}
              />
            )}
          </ApolloConsumer>
          <Layout className={!isLogin && 'initial-layout'}>
            <Sider isLogin={isLogin} showSider={showSider} />
            <Switch>
              {
              routes.map(
                (route) => (
                  <MakeRouteWithSubRoutes
                    key={route.path}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...props}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...route}
                  />
                ),
              )
            }
            </Switch>
          </Layout>
          <FooterPage isLogin={isLogin} />
        </SiderProvider>
      </Layout>
    </UserInfoContextProvider>
  );
}, (prevProps, nextProps) => (
  prevProps.showSider === nextProps.showSider
  && prevProps.isLogin === nextProps.isLogin
  && prevProps.path === nextProps.path
));

LayoutView.propTypes = {
  isLogin: PropTypes.bool,
  showSider: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

LayoutView.defaultProps = {
  isLogin: true,
};

export default withBanner(withLoading(LayoutView));
