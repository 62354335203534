import React from 'react';
import {
  Layout,
} from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import siderMenus from './constants';

import SiderContent from './SiderContent';
import SiderDrawer from './SiderDrawer';

const { Sider } = Layout;

function SiderView(props) {
  const { t, isLogin, showSider } = props;
  const menu = siderMenus(t);

  return (
    showSider && (
      isLogin && (
        [
          <Sider key="normal-sider" width={280}>
            <SiderContent siderMenus={menu} />
          </Sider>,
          <SiderDrawer
            key="responsive-sider"
            siderMenus={menu}
          />,
        ]
      )
    )
  );
}


SiderView.propTypes = {
  t: PropTypes.func.isRequired,
  isLogin: PropTypes.bool.isRequired,
  showSider: PropTypes.bool.isRequired,
};

export default withTranslation()(SiderView);
