import React from 'react';
import { Redirect } from 'react-router-dom';
import routesPaths from '../../../routes/routesPaths';

function RouteAccess(props) {
  const {
    component: Component, hasAccess, ...inComingProps
  } = props;

  if (hasAccess) {
    return <Component {...inComingProps} />;
  }

  return (
    <Redirect to={{
      pathname: routesPaths.home,
    }}
    />
  );
}

export default RouteAccess;
