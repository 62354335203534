import React, { useEffect, useState } from 'react';
import {
  Redirect,
} from 'react-router-dom';
import withPermission from '../../HOC/withPermission';
import routesPaths from '../routesPaths';

function PrivateRoute(props) {
  const {
    renderProps,
    component: Component,
    isLogin,
    setLoginState,
    setSiderState,
    getPath,
    loading,
  } = props;

  const [AccessComponent, setAccessComponent] = useState();


  useEffect(() => {
    const NewComponent = withPermission({ name: renderProps.name, type: 'route' })(Component);
    setAccessComponent(() => NewComponent);
  }, [Component, renderProps.name]);

  if (!isLogin) {
    return (
      <Redirect
        to={routesPaths.login}
      />
    );
  }

  if (!AccessComponent) return null;

  return (
    <AccessComponent
      setLoginState={setLoginState}
      setSiderState={setSiderState}
      loading={loading}
      getPath={getPath}
      {...renderProps}
    />
  );
}

export default PrivateRoute;
